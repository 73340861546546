import { createRouter, createWebHashHistory } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import store from '../store/index.js'

// 09/06/23 CAMBIO METODO DI ROUTING , utilizzo i componente importandoli tramite arrow function , 
// in questo modo le pagine vengono reindirzzate singolarmente rendendo il codice più veloce
const routes = [
  {
    path: '/',
    name: '/HomeView',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/HomeView.vue")
  },
  {
    path: '/Privacy-by-design',
    name: '/PrivacyBydesign',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/PrivacyBydesign.vue")
  },
  {
    path: '/change-password/:token',
    name: '/ChangePassword',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/ChangePassword.vue")
  },
  {
    path: '/subscription-page',
    name: '/AbbonamentiPage',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/AbbonamentiPage.vue")
  },

  {
    path: '/payment-page',
    name: '/PagamentoPage',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/PagamentoPage.vue")
  },

  {
    path: '/success-payment',
    name: '/PagamentoSuccesPage',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/PagamentoSuccesPage.vue")
  },

  {
    path: '/subscription-expired-page',
    name: '/AbbonamentoscadutoPage',
    component: () => import(/* webpackChunkName: "HOME" */"@/views/AbbonamentoscadutoPage.vue")
  },

  {
    path: '/learning-analytics-page',
    name: 'LearningAnalytics',
    component: () => import(/* webpackChunkName: "LearningAnalyticsPage" */"@/views/LearningAnalytics.vue")
  },
  {
    path: '/hr-analytics-page',
    name: 'HrAnalyticsPage',
    component: () => import(/* webpackChunkName: "HrAnalyticsPage" */"@/views/HrAnalyticsPage.vue")
  },
  {
    path: '/hr-reporting-page',
    name: 'HrReportingNewPage',
    component: () => import(/* webpackChunkName: "HrReportingPage" */"@/views/HrReportingNewPage.vue")
  },
  {
    path: '/accounting-page',
    name: 'AccountingPage',
    component: () => import(/* webpackChunkName: "AccountingPage" */"@/views/AccountingPage.vue")
  },
  {
    path: '/skills-page',
    name: 'SkillsPage',
    component: () => import(/* webpackChunkName: "SkillsPage" */"@/views/SkillsPage.vue")
  },
  {
    path: '/performance-page',
    name: 'PerformancePage',
    component: () => import(/* webpackChunkName: "PerformancePage" */"@/views/PerformancePage.vue")
  },
  {
    path: '/salary-review-page',
    name: 'SalaryReview',
    component: () => import(/* webpackChunkName: "SalaryReviewPage" */"@/views/SalaryReview.vue")
  },
  {
    path: '/help-desk',
    name: 'HelpDesk',
    component: () => import(/* webpackChunkName: "HelpDesk" */"@/views/HelpDesk.vue")
  },
  {
    path: '/integration',
    name: 'IntegrationPage',
    component: () => import(/* webpackChunkName: "HelpDesk" */"@/views/IntegrationPage.vue")
  },

  {
    path: '/zucchetti',
    name: 'ZucchettiApiConnect',
    component: () => import(/* webpackChunkName: "HelpDesk" */"@/views/ZucchettiApiConnect.vue")
  },


  {
    path: '/zucchetti-details',
    name: 'ZucchettiDetails',
    component: () => import(/* webpackChunkName: "HelpDesk" */"@/views/ZucchettiDetails.vue")
  },
  // {
  //   path: '/dashboard',
  //   name: 'DashBoard',
  //   component: DashBoard
  // },
  // {
  //   path: '/files-page',
  //   name: 'FilesPage',
  //   component: FilesPage
  // }, 
 
  // {
  //   path: '/archivio-page',
  //   name: 'ArchivioPage',
  //   component: () => import(/* webpackChunkName: "ArchivioPage" */"@/views/ArchivioPage.vue")
  // },

 

  // {
  //   path: '/hr-reporting-old-page',
  //   name: 'HrReportingPage',
  //   component: () => import(/* webpackChunkName: "HrReportingPage" */"@/views/HrReportingPage.vue")
  // },


  // {
  //   path: '/test-page',
  //   name: '/testPage',
  //   component: () => import(/* webpackChunkName: "HOME" */"@/views/testPage.vue")
  // },
  // {
  //   path: '/test-page-two',
  //   name: '/TestPageTwo',
  //   component: () => import(/* webpackChunkName: "HOME" */"@/views/TestPageTwo.vue")
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// const isAuthenticated = () => store.state.isAuthenticated;

// router.beforeEach((to, from, next) => {
//   if (to.path === '/change-password') {
//     next(); // Consenti l'accesso alla pagina di cambio password
//   } else {
//     next(); // Prosegui la navigazione per le altre rotte
//   }
// })



// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })

export default router

/* eslint-disable no-unused-vars */
import { createStore } from 'vuex';
import Swal from 'sweetalert2';
import axios from 'axios';
// import { useI18n } from 'vue-i18n';

export default createStore({
  // Gli state non sono altro che i data dei file presenti nelle altre app vue
  state: {
    hasScrolled: false,
    zucchettiConnect : false,
    zucchettiIdConnected:null,
    infoTokenZucchettiId:null,
    urlRequestDataZucchetti: '',
    global_variable_color_chart:null,
    chat_active:false,
    gpt_request_loader:false,
    status_response_chat:true,
    array_chat:[],
    token: "",
    userId:null,
    username: null,
    email : null,
    gpt_activate: false,
    isAuthenticated: false,
    nav_bar_status: false,
    ticket_file_id: null,
    user_company_id:null,
    user_groups_data:[],
    validate_data_file:{},

    can_operate:true,
      // DATI QUANDO VIENE CREATO UN NUOVO ABBONAMENTO , NECESSARIO SEGUIRE IL FLUSSO ALTRIMENTI BISOGNA RINIZIARE DALL INIZIO 
    nome_nuova_azienda:"",
    e_mail_nuova_azienda:"",
    partita_iva_nuova_azienda:"",

      // USERNAME E EMAIL NUOVO UTENTE , QUESTE VARIABILI SONO UTILIZZATE SOLO PER QUANDO VIENE CREATA LA PRIMA AZIENDA CON UN PRIMO ABBONAMENTO 
    username_nuovo_utente:"",
    email_nuovo_utente:"",

    // DATI PER ABBONAMENTO NELLO STORE PER ARRIVARE AL PAGAMENTO 
    Abbonamento_selected:[],
    id_listino_prezzi:null,
    biennio_choice:null,
    id_coupon:null,
    id_scontistica:null,
    max_account: null,
    // openAi_key: "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",
    locale: localStorage.getItem("locale") || "default_locale_value",
    lenguage_selected: "Fornisci la risposta tradotta in lingua ",
    question: " Analizzami questi dati creando un report dettagliato, senza menzionare le chiavi presenti nel json, non descrivere i colori con l'esadecimale. La risposta dev'essere comunicata ad un pubblico generico.",
    question_a: " Scrivi un report su queste analisi , inizia con REPORT ",
    question_b:
      " Cerca e metti in evidenza eventuali correlazione tra le seguenti analisi , inizia con CORRELAZIONI ",
    question_c:
      " Scrivi una conclusione finale su queste analisi, inizia con CONCLUSIONI  ",
    question_2:
      " un report con un analisi completa dei dati con relativa descrizione dividendola in blocchi per ogni set di dati. Cerca e metti in evidenza eventuali correlazione tra tutti i blocchi di dati. Scrivi una conclusione finale con data e firma HRCoffee: ",
  },
  // setup() {
  //     const { t, locale, setLocale } = useI18n()
  //     return {
  //         t,
  //         locale,
  //         setLocale,
  //     }
  // },
  // le mutations sono come i methods
  mutations: {
    setHasScrolled(state, value) {
      state.hasScrolled = value;
    },

    setNewColorChart(state, nuovoValore) {
      console.log("setNewColorChart state", nuovoValore);
      // Modifica la variabile x nello stato
      state.global_variable_color_chart = nuovoValore;
    },

    toggleChat(state) {
      state.chat_active = !state.chat_active;
    },
    gpt_request_loader_state(state) {
      state.gpt_request_loader = !state.gpt_request_loader;
    },
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state, token) {
      state.token = token;
      state.isAuthenticated = false;
      localStorage.removeItem("token");
    },

    ShowNav(state) {
      // console.log(state.nav_bar_status);
      if (state.nav_bar_status == true) {
        state.nav_bar_status = false;
      } else {
        state.nav_bar_status = true;
      }
    },
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem("locale", locale);
    },
    setUserId(state, userId) {
        state.userId = userId;
      },
    setEmail(state, email){
        state.email = email
    },
    setUsername(state, username){
        state.username = username
    },

    setUserGroup(state, user_groups_data){
      state.user_groups_data = user_groups_data
    },

    setGptActivate(state, value) {
      state.gpt_activate = value;
    },
    setNewCompany(state,value){
      state.nome_nuova_azienda = value
    },
    
    setNewMailCompany(state,value){
      state.e_mail_nuova_azienda = value
    },
    SetNewPartitaIva(state,value){
      state.partita_iva_nuova_azienda = value
    },
    SetNewFirstUser(state,value){
      state.username_nuovo_utente = value
    },
    SetNewFirstEmail(state,value){
      state.email_nuovo_utente = value
    },
    SetSubscription(state,value){
      state.Abbonamento_selected = value
    },
    SetIdListinoPrezzi(state,value){
      state.id_listino_prezzi = value
    },
    SetIsBiennio(state,value){
      state.biennio_choice = value
    },
    SetIdCoupon(state,value){
      state.id_coupon = value
    },
    
    SetIdScontistica(state,value){
      state.id_scontistica = value
    },
    SetMaxAccount(state,value){
      state.max_account = value
    }


  },
  actions: {
    // FUNZIONE GENERALE DA RICHIAMARE IN CONNESSIONE PER VERIFICARE SE L UTENTE è COLLEGATO A ZUCCHETTI 
    async zucchettiConnectionControl({commit},{userId}){
      const url = "/api/info_connection/?user_logged_splash=" + userId
      // console.log('zucchettiConnectionControl',url)
      
      try {
        // RECUPERO I DATI DELL UTENTE DOPO LA CONNESSIONE SE ESISTE SETTO A TRUE
        const response = await axios.get(url)
        // console.log(response.data)
        if(response.data.length > 0){
          // console.log("connesso",response)
          this.state.zucchettiConnect = response.data[0].connected
          this.state.zucchettiIdConnected = response.data[0].id
          this.state.infoTokenZucchettiId = response.data[0].info_token[0].id
        }else{
          this.state.zucchettiConnect = false
        }
      } catch (error) {
        // console.log('zucchettiConnectionControl error')
        console.error(error)
      }
    },

    /**
     * Convert special character in the given string to the specified code.
     *
     * @param {string} string - the input string
     * @param {string} specialCharacter - the special character to be replaced
     * @param {string} codeEncoded - the code to replace the special character with
     * @return {string} the modified string with the special character replaced
     */
    ConvertSpecialCharacter({commit},{string, specialCharacter, codeEncoded}) {
      // console.log('entrato in ConvertSpecialCharacter')
      if (string.includes(specialCharacter)){
        // console.log('if ConvertSpecialCharacter',string)
        return string.replace(specialCharacter, codeEncoded)
      }else{
        // console.log('else ConvertSpecialCharacter ',string)
        return string 
      }
    },

    async zucchetttiExpiredSessionReload({commit}){
      const url = "/zucchetti/Reload/" 
      // console.log('zucchetttiExpiredSessionReload',url)
      
      try {
        // RECUPERO I DATI DELL UTENTE DOPO LA CONNESSIONE SE ESISTE SETTO A TRUE
        const response = await axios.get(url)
        // console.log(response)
      } catch (error) {
        // console.log('zucchetttiExpiredSessionReload error')
        console.error(error)
      }
    },

    saveNewcolorGeneralFunction({commit},{dataChart,textSucces,textError,useApiData}){
      console.log("DATACHART IN SAVENEWCOLOR STORE",dataChart);
      const id_graph = dataChart.object.id;
      let dictDataJson = JSON.stringify(dataChart.dictData)
      console.log("saveNewcolorGeneralFunction in store JSON: ",dictDataJson)
      // URL dell'API e dati da inviare nel corpo della richiesta PATCH
      const apiUrl = useApiData ? "/api/graphDataFile_zucchetti/" + id_graph + "/" : "/api/graphDataFile/" + id_graph + "/"
      // const dict_data = dataChart.dictData
      const formData = {
        dict_data: dictDataJson,
      };

      // Esegui la chiamata PATCH utilizzando Axios
      axios
        .patch(apiUrl, formData)
        .then((response) => {
          // Gestisci la risposta di successo
          // console.log("Risposta:", response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: textSucces,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          // Gestisci gli errori durante la chiamata
          console.error("Errore durante la chiamata PATCH:", error);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: textError,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },

    scrollIntoViewFromArray({ commit, state }, { array, index }) {
      return new Promise((resolve) => { // Rimuovi reject dai parametri se non lo usi
        // Esegue la funzione solo se 'hasScrolled' è false e l'array ha più di 1 elemento
        if (!state.hasScrolled && array.length > 1) {
          // console.log('entrato in if')
          const elementId = array[index].object.type;
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
            commit('setHasScrolled', true); // Imposta 'hasScrolled' su true dopo lo scroll
            // resolve("Scroll completato con successo");
          } else {
            // Se l'elemento non viene trovato, risolve comunque la promessa senza errore
            // console.log("Elemento non trovato, nessuna azione intrapresa.");
            // resolve("Elemento non trovato, nessuna azione intrapresa.");
            resolve('no element')
          }
        } else {
          // Se le condizioni non sono soddisfatte, risolve comunque la promessa senza errore
          // console.log("Condizioni non soddisfatte per lo scroll, nessuna azione intrapresa.");
          // resolve("Condizioni non soddisfatte per lo scroll, nessuna azione intrapresa.");
          resolve('no element')
        }
      });
    },
    
    GetValidateBeforeFile(){
      axios.get('analitics/Validate_Before_File/').then(response => {
        // console.log("response.data GETVALIDATEBEFORFILE",response.data)
        this.state.validate_data_file = response.data
        this.state.can_operate = response.data.can_operate
      })
    },

    // Azione per aprire una modale informativa SweetAlert , RICEVE un titolo e dun array con i paragrafi
    openModalInfo({ commit }, { title, paragraphs }) {
      // Crea il contenuto HTML della modale unendo i paragrafi
      const htmlContent = paragraphs.map(paragraph => `<div style="text-align: left;">${paragraph}</div><br>`).join('');
      // Utilizza SweetAlert per aprire la modale
      Swal.fire({
        title: title,     // Titolo della modale
        html: htmlContent // Contenuto HTML della modale
      });
    },


    // SweetAlert per informazioni
    sweetAlertInfo({ commit }, { title, icon }) {
      
      Swal.fire({
        title: title,     // Titolo della modale
        icon: icon
      });
    },


    // FUNZIONE PER SINGOLO CHART DA BACKEND 
    // PASSO COME RIFERIMENTO JSON_STRING CHE SONO I DATI DEL GRAFICO DA ANALIZZARE E L OGGETTO DEL GRAFICO (CHART)
    // eslint-disable-next-line no-unused-vars
    async GptApiRequest({ commit }, { json_string, chart, useApiData }) {
      // console.log("useapidata: ", useApiData)
      // console.log("================================ ID GRAFICO ================= :" , chart.id)
      // console.log(chart.messageGpt)
      // console.log("question_GPT: ", chart.questionGPT)
      let questionGPT = null
      if (chart.questionGPT != null) {
        questionGPT = chart.questionGPT
      }else{
        questionGPT = this.state.question
      }
      if (this.state.locale === "it") {
        this.state.locale = "italiana";
      } else if (this.state.locale === "en") {
        this.state.locale = "inglese";
      } else if (this.state.locale === "es") {
        this.state.locale = "spagnola";
      }
      chart.is_loading = true;
      const question =
        this.state.lenguage_selected +
        this.state.locale +
        "." +
        questionGPT 
      // console.log("DOMANDA COMPLETA !!!! ", question);

      try {
        const url = '/chat_GPT/Chat_GPT_graph/';
        const response = await axios.post(url, 
        { 
          model_GPT: 'gpt-4-1106-preview',
          question: question, 
          json_string: json_string,
          id_grafico: chart.id,
          is_zucchetti: useApiData
        });
        // const response = await axios.post(url, body, { headers });

          // //* RICEVO IN RISPOSTA L ID TICKET 
          const id_ticket = response.data
          // console.log("response data ticket: ", response.data)

          // //* DICHIARO LO STATUS DELLA RISPOSTA DEL TICKET 
          let response_status_ticket = null 
          //RECUPERO IL TICKET ID E CONTROLLO LO STATUS CON IL SET INTERVAL RICHIAMANDO LA FUNZIONE FIN QUANDO NO RESTITUISCE "F"
          const intervalId = setInterval(async () => {
            // PRENDO I DATI DALLA CHIAMATA OGNI VOLTA PER VERIFICARE 
            response_status_ticket = await this.dispatch('GetStatusTicketGpt', { id: id_ticket, useApiData: useApiData });
            // console.log("response status ticket: ", response_status_ticket.data.status);
            // SE RAGGIUNGO LO STATUS F 
            if (response_status_ticket.data.status === "F") {
              // console.log("clear interval")
              // console.log("Il ticket è nello stato desiderato. Interrompo l'intervallo.");
              clearInterval(intervalId); // Interrompe l'intervallo una volta raggiunto lo stato desiderato
              const graph_id = response_status_ticket.data.graph_id
              let urlGraphData = useApiData ? "/api/graphDataFile_zucchetti/" + graph_id + "/" : "/api/graphDataFile/" + graph_id + "/"
              const response_graph = await axios.get(urlGraphData);
              // console.log("response_graph-----------------:", response_graph)
              chart.is_loading = false;
              chart.messageGpt = response_graph.data.commento_GPT;
              chart.show_gpt_button = false;
            
            } else if(response_status_ticket.data.status === "E"){
              chart.is_loading = false;
              // console.log("errore"),

              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Siamo spiacenti! c è stato un errore nella risposta",
                showConfirmButton: false,
                timer: 3000,
              });
              clearInterval(intervalId); 
            }
          }, 1000); // Esegui ogni secondo (puoi regolare l'intervallo a tuo piacimento)

        // return [total_tokens,prompt_tokens,completion_tokens];
      } catch (error) {
        console.error(error);
      }
    },

    // PASSARE IL Chart.OBJECT 
    async GptApiRequestForThread({ commit }, { graph_id }) {
      // console.log("===== ID GRAFICO GptApiRequestForThread ===== :", graph_id)
    
      try {
        const url = '/chat_GPT/Chat_GPT_graph/?id_grafico=';
        const response = await axios.get(url + graph_id);
        const ticketIdThread = response.data.id_ticket;
        // console.log('ticketIdThread', ticketIdThread);
    
        return ticketIdThread;
      } catch (error) {
        console.error(error);
      }
    },

    // GPT REPORT CON GPT-3
    // eslint-disable-next-line no-unused-vars
    async GptApiReportRequest({ commit }, { json_string, question1,question2,question3,id_file }) {
      // console.log("QUESTION 1   :", question1)
      // console.log("question2   :", question2)
      // console.log("question3   :", question3)
      // console.log("id_file   :", id_file)
        if (this.state.locale == "it") {
            this.state.locale = "italiana"
        } else if(this.state.locale == "en"){
            this.state.locale = "inglese"
        } else if(this.state.locale == "es"){
            this.state.locale = "spagnola"
        }
        const { locale, lenguage_selected} = this.state; // Destrutturazione dello stato
        const question_complet_1 =   question1 + "." + lenguage_selected + locale + ":";
        const question_complet_2 =   question2 + "." + lenguage_selected + locale + ":";
        const question_complet_3 =   question3 + "." + lenguage_selected + locale + ":";
        // // console.log("domanda in VUEx: ",question)

        try {
          const url = '/chat_GPT/Chat_GPT_report/';
          //* QUI VIENE CREATO IL TICKET
          const response = await axios.post(url, 
          { 
            model_GPT: 'gpt-4-1106-preview',
            question1 : question_complet_1,
            question2 : question_complet_2,
            question3 : question_complet_3,
            commenti: json_string,
            id_file:id_file
          });
          // console.log("ticket report",response.data)
          
          // //* RICEVO IN RISPOSTA L ID TICKET 
          const id_ticket = response.data
          return id_ticket
          
     
        } catch (error) {
            Swal.fire('ops! GPT non risponde', '', 'error');
            console.error(error);
        }


    },

    // eslint-disable-next-line no-unused-vars
    async GptRequestChat({ commit }, { question_chat,index }) {
          // // console.log("GptRequestChat index:",index)
          // Chiamare CreateTicketfileGpt nello store si usa dispatch
          // await this.dispatch('CreateTicketfileGpt');

          try {
            this.state.array_chat.push({ question: question_chat })
              // Attendere che il div venga creato
              // console.log(index)
              await waitForElementToBeCreated(index);
              scrollToMessage(index)
          } catch (error) {
            // console.log(error)
          }finally{
            // // console.log("index : ", index , " question chat:", question_chat)
            // scrollToMessage(2)
          }

          // Chiamata POST per salvare i token
          try {
            const url = '/chat_GPT/Chat_GPT/';
            const response = await axios.post(url, 
            { 
              user_input: question_chat ,
              model_GPT: 'gpt-4-1106-preview', 
            });

            // console.log("response data ticket: ", response.data)
            const id = response.data
            let response_status_ticket = null
            this.commit('gpt_request_loader_state'); // Chiamata alla mutazione
            // RECUPERO IL TICKET ID E CONTROLLO LO STATUS 
            const intervalId = setInterval(async () => {
              response_status_ticket = await this.dispatch('GetStatusTicketGpt', { id: id, useApiData: false });
              // console.log("response status ticket: ", response_status_ticket.data.status);
              if (response_status_ticket.data.status === "F") {
                // console.log("clear interval")
                // console.log("Il ticket è nello stato desiderato. Interrompo l'intervallo.");
                clearInterval(intervalId); // Interrompe l'intervallo una volta raggiunto lo stato desiderato
                const conversation_id = response_status_ticket.data.conversation_id
                const response_chat = await axios.get("api/conversation/" + conversation_id);
                // QUI RECUPERO ID DELLA CONVERSAZIONE E PASSO I DATI CHE MI SERVONO NELLO STATE 
                // console.log("response id conversation", response_status_ticket.data.conversation_id)
                // console.log("response_chat", response_chat)
                this.commit('gpt_request_loader_state'); // Chiamata alla mutazione
                // console.log(response_chat.data.output_message);
                // // console.log(response.data.status)
                // this.state.status_response_chat = response.data.status
                this.state.array_chat.push({ response: response_chat.data.output_message })
              } else if(response_status_ticket.data.status === "E"){
                // console.log("errore"),
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Siamo spiacenti! c è stato un errore nella risposta",
                  showConfirmButton: false,
                  timer: 3000,
                });
                clearInterval(intervalId); 
                this.commit('gpt_request_loader_state'); // Chiamata alla mutazione
              }
            }, 1000); // Esegui ogni secondo (puoi regolare l'intervallo a tuo piacimento)
            // Esegui le azioni desiderate dopo l'aggiornamento dei dati
          } catch (error) {
            // Gestisci gli errori qui
            // console.log("si è verificato un errore nel pushare i token a database")
            console.error(error);
          } finally{
            // console.log("chiamata terminata")
          }

    },

    async GetStatusTicketGpt({ commit }, { id, useApiData }) {
        // console.log("funzione dallo store getstatusticketgpt")
        // console.log("chiamata ticketFileGpt con ID:", id);
        // console.log("GetStatusTicketGpt_use_api_Data: ", useApiData)
        let url = useApiData ? `/chat_GPT/Ticket_gpt_api/?idTicket=${id}&is_zucchetti=${useApiData}` : `/chat_GPT/Ticket_gpt_api/?idTicket=${id}`
        // console.log("GetStatusTicketGpt: ", url)
        try {
        const response = await axios.get(url);
        // console.log("risposta in get status ticket", response.data.status);
        
        return response
          // Esegui le azioni desiderate dopo l'aggiornamento dei dati
        } catch (error) {
          // Gestisci gli errori qui
          console.error("si è verificato un errore nella chiamata a Ticket_gpt", error);
        }
    },

    async GetStatusTicketGraph({ commit }, {personal_url, id}) {
      // console.log("chiamata GetStatusTicketGraph in VUEX con ID:", id);
      const url = personal_url + id; // Aggiungi l'ID all'URL
      try {
        const response = await axios.get(url);
        // console.log("risposta in get status ticket", response.data.status);
        
        return response
        // Esegui le azioni desiderate dopo l'aggiornamento dei dati
      } catch (error) {
        // Gestisci gli errori qui
        console.error("si è verificato un errore nella chiamata a Ticket_gpt", error);
      }
    },
    

  },

//   FINE ACTION 
  

  modules: {},
});

// Funzione di generazione iterativa
// eslint-disable-next-line no-unused-vars
async function generateIteratively(url, body, headers, maxTokens) {
    let generatedText = "";
  
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await axios.post(url, body, { headers });
      const choices = response.data.choices;
      const lastChoice = choices[choices.length - 1];
      const generatedTokens = lastChoice.message.total_length;
      generatedText += lastChoice.message.content;
  
      if (generatedTokens >= maxTokens) {
        break;
      }
  
      // Aggiorna il messaggio di input per la prossima iterazione
      body.messages[0].content = lastChoice.message.content;
      body.messages[0].role = "user";
    }
  
    return generatedText;
  }

  function scrollToMessage(index) {
    const chatDiv = document.getElementById("chat_box");
    const messageElement = document.getElementById(index);
    // console.log(messageElement)
    if (chatDiv && messageElement) {
      chatDiv.scrollTo({
        top: messageElement.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  // Funzione che restituisce una promessa che attende che l'elemento con l'ID specifico venga creato
function waitForElementToBeCreated(index) {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      const messageElement = document.getElementById(index);
      // console.log("il div non esiste",messageElement)
      if (messageElement) {
        // console.log("esiste il div", index)
        clearInterval(checkInterval); // Smetti di controllare quando l'elemento è presente
        resolve();
      }
    }, 100); // Controlla ogni 100 millisecondi
  });
}
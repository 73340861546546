<template >
  <header class=" ">
    <div class="d-flex justify-content-between align-items-center header">
      
      <!-- MENU E LOGO  -->
      <div class="d-flex align-items-center">
        <div >
          
          <router-link to="/" ><img src="./../../assets/img/logo.png" alt="logo" class="logo"  ></router-link>
          
        </div>
        <div v-if="navBarStatus == false" @click="ShowNavBar" class="mx-2 pointer">
            <div class="d-flex align-items-center gap-2 menu" v-if="CanOperate">
                <font-awesome-icon icon="fa-solid fa-bars" class="font_awesome_style" /> 
                <span>Menu</span>
            </div>
        </div>
      
      </div>
        
      <!-- DASHBOARD E LOGOUT  -->
      <div class="d-flex align-items-center mx-3 flex-wrap">
        <!-- {{ $t('italiano') }} -->
        <!-- <select v-model="selectedLocale" @change="setNewLocale(selectedLocale)" >
          <option v-for="lenguage,index in lenguages " :key="index" :value="lenguage.code">{{  lenguage.lenguage }}</option>
        </select> -->
        <lenguage-swithcer/>
        <div class="mx-3 username_visibile"> <b> {{username}} </b> </div>
        <!-- !METODO DELLE BANDIERE INSTLLATO CON FlagIcon , NON FUNZIONA NELLE OPTION IN QUANTO NON SUPPORTATO , RIUTILIZZABILE ALTROVE  -->
        <!-- <flag iso="it" /> -->
        <!-- !BANDIERE INSTALLATE DA NODE MODULE , NON FUNZIONANTI NELLE OPTION CAPIRE IN SEGUITO PERCHE -->
        <!-- <i class="flag-icon flag-icon-it"></i>
        <i class="flag-icon flag-icon-gb"></i>
        <i class="flag-icon flag-icon-es"></i> -->
        <!-- <router-link to="dashboard" class="mx-3 dashboard">DashBoard</router-link> -->

        <div class="pointer" @click="ModalLogoutShow" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Logout">
          <font-awesome-icon icon="fa-solid fa-door-open" class="door_open" />
        </div>
        <div class="modal_logout" v-show="modal_logout_show" >
          <p><b>Sicuro di voler effettuare il logout ? </b> </p>
          <div class="d-flex gap-3 justify-content-center">
            <div class="btn btn-primary" @click="logoutFunction" >Si</div>
          <div class="btn btn-danger " @click="ModalLogoutShow">No</div>
          </div>
          
        </div>

      </div>
    </div>

  </header>
</template>

<script>
import store from './../../store';
import { useI18n } from 'vue-i18n'
// eslint-disable-next-line no-unused-vars
import it from './../../locales/it.json'
import LenguageSwithcer from '../nano/LenguageSwithcer.vue';

export default {
  components: { 
    LenguageSwithcer,

  },
    name: 'BaseHeader',
    setup() {
    const { t, locale, setLocale } = useI18n()
    return {
      t,
      locale,
      setLocale,
    }
  },
    data() {
      return {
        modal_logout_show: false,
        selectedLocale: this.locale,
        lenguages : [
          { code: "it" , lenguage: "Italiano"},
          { code: "en" , lenguage: "Inglese"},
          { code: "es" , lenguage: "Spagnolo"},
        ],
        n_md:900,
        shouldDisplayIcon: false
        
      }
    },

    created() {

    window.addEventListener('resize', this.updateShouldDisplayIcon);
    this.updateShouldDisplayIcon();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateShouldDisplayIcon);
    },
    mounted(){
      // console.log('lingua:',this.locale)
      // // console.log(localStorage.locale)
      if(this.locale == undefined){
        // console.log("this locale lingua non impostata ")
      }
      this.locale = localStorage.locale
      this.selectedLocale = localStorage.locale
      // // console.log(it)
    },
    methods: {
      resetSidebarColor() {
        this.sidebarBackgroundColor = ''; // Azzera il valore del colore
        localStorage.removeItem('sidebarColor'); // Rimuovi il colore dal local storage
      },
        setNewLocale(locale) {
          this.$store.commit('setLocale', locale); // imposto la lingua nel local storage
          this.locale = locale;
        },
        // NEL LOGOUT VENGONO ANCHE AZZERATI I DATI DEL UTENTE CONNESSO 
        logoutFunction() {
            const userId = null
            const email = null
            const username = null
            // console.log("rimozione token")
            this.$store.commit('removeToken', '');
            this.$router.push('/')
            this.resetSidebarColor();
            this.$store.commit('setUserId', userId); // Utilizza una mutazione personalizzata nello store per assegnare l'ID dell'utente
            this.$store.commit('setEmail', email); 
            this.$store.commit('setUsername', username); 
            store.state.user_company_id = null
            // In alternativa puoi utilizzare la funzione localStorage.removeItem('token')
            // per rimuovere il token dal localStorage
            
        },
        updateShouldDisplayIcon() {
          this.shouldDisplayIcon = window.innerWidth >= this.n_md;
        },

        ShowNavBar(){
            this.$store.commit('ShowNav');
        },
        ModalLogoutShow(){
          if(this.modal_logout_show == false){
            this.modal_logout_show = true 
          }else{
            this.modal_logout_show = false
          }
        
        }
        
    },
    computed:{
      username() {
        return store.state.username;
      },
      // Attraverso la computed prendo elmenti che rimangono in ascolto in questo caso dallo store di vuex
      // in questo modo posso verificare se lo stato di autenticazione è impostato a true 
      navBarStatus(){
          return store.state.nav_bar_status
          },
      CanOperate(){
        return store.state.validate_data_file.can_operate
      },
  },
}
</script>

<style lang="scss" scoped>
  .modal_logout{
    position: absolute;
    padding: 10px;
    width: 300px;
    top: 60px;
    right: 10px;
    display: flex;
    flex-direction: column;
    background-color: $hrcoffee-text-primary;
    border-radius: 15px;
    box-shadow: -5px -1px 15px 5px $hrcoffe-shadow-grey;

    animation: rightEnter ease 1s;
            animation-iteration-count: 1s;
            animation-fill-mode: forwards;
    
  }
  .header{
    height: 50px;

    .dashboard,.door_open,.menu{
      text-decoration: none;
      color: $hrcoffee-darkblue;
      font-weight: bold;
      transition: 0.3s;
    }
    .dashboard:hover ,.door_open:hover,.menu:hover{
      color: $hrcoffe-darkskyblue;

    }
    .logo{
      width: 100px;
    }
    .font_awesome_style{
    font-size: 20px;
    }

  }
</style>
<template>
        <div class="d-flex">
            <!-- {{ $t('italiano') }} -->
            <!-- <select v-model="selectedLocale" @change="setNewLocale(selectedLocale)" class="lenguage_style">
                <option v-for="lenguage, index in lenguages " :key="index" :value="lenguage.code">{{ $t(lenguage.lenguage) }}
                </option> -->
                <!-- <option value="it"> <i class="flag-icon flag-icon-it"></i>🇮🇹 {{ $t('lenguage.Italiano') }}</option>
                <option value="en"> <i class="flag-icon flag-icon-gb"></i>🇬🇧 {{ $t('lenguage.Inglese') }}</option>
                <option value="es"> <i class="flag-icon flag-icon-es"></i>🇪🇸 {{ $t('lenguage.Spagnolo') }}</option> -->
            <!-- </select> -->
            <div>
                <div class="dropdown">
                    <button class="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <!-- {{selectedLocale}} -->
                        {{"Language: "+ selectedLocale}}
                    </button>
                    <ul class="dropdown-menu ">
                        <li v-for="lenguage, index in lenguages" :key="index" class="dropdown-item pointer" >
                                <div @click="setNewLocale(lenguage.code, lenguage.code)">
                                    <i :class="lenguage.flag"></i> {{ $t(lenguage.lenguage) }}
                                </div>
                        </li>
                    </ul>
                </div>
                
            </div>

        </div>
      
</template>

<script>
import { useI18n } from 'vue-i18n'
// eslint-disable-next-line no-unused-vars
import it from './../../locales/it.json'

export default { 
name:'LenguageSwithcer',
setup() {
    const { t, locale, setLocale } = useI18n()
        return {
            t,
            locale,
            setLocale,
        }
    },
    data() {
        return {
            modal_logout_show: false,
            selectedLocale: "",
            lenguages: [
                {flag:"flag-icon flag-icon-it", code: "it", lenguage: "Italiano" },
                {flag:"flag-icon flag-icon-gb", code: "en", lenguage: "Inglese" },
                {flag:"flag-icon flag-icon-es", code: "es", lenguage: "Spagnolo" },
            ]

        }
    },
    mounted() {
        // console.log('lingua:', this.locale)
        this.locale = localStorage.locale
        this.selectedLocale = localStorage.locale
        if(this.selectedLocale == undefined){
            // // console.log("lingua non definita")
            // SE NON è STATA SELEZIONATA NESSUNA LINGUA DI DEFAULT VA IN INGLESE 
            this.selectedLocale = this.lenguages[1].code
            this.setNewLocale(this.lenguages[1].code,this.lenguages[1].code)
        }
        // console.log("lingua selectedLocale",this.selectedLocale)
      
        // // console.log(it)
    },
    methods: {
        setNewLocale(locale, selected_lenguage) {
            this.$store.commit('setLocale', locale); // imposto la lingua nel local storage
            this.locale = locale;
            this.selectedLocale = selected_lenguage
        },

    },
}
</script>

<style lang="scss" scoped>
.lenguage_style{
    background-color: white;
    border: 1px solid $hrcoffee-darkblue;
    padding: 2px 10px;
    border-radius: 50px;
}

</style>
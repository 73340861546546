<template>
    <div v-cloak>
        <section class=" login_container d-flex justify-content-center align-items-center " >
            <div class="login_box row gx-0 w-100 mx-4 ">
                <!-- COL WELCOME  -->
                <div class="welcome_content col rounded-start d-flex flex-column justify-content-between bg_image">
                    <div class="m-3 d-flex justify-content-between">
                        <img src="../assets/img/logo.png" class="logo" alt="">
                        <!-- <h1 class="title_site">SPLASH.In <font-awesome-icon icon="fa-solid fa-chart-line" /></h1> -->
                        <div >
                            <lenguage-swithcer/>
                        </div>
                        
                    </div>
                    <div class="d-flex flex-column align-items-end title_site mx-3">
                        <!-- <h1><b>{{$t('Welcome')}}</b> </h1>
                        <h5>{{$t('WelcomeSubtitle')}}</h5> -->
                    </div>
                    <div class="d-flex justify-content-start mx-4">
                        <p><b>Powered by Hrcoffee</b> </p>
                    </div>
                </div>
                <!-- COL LOGIN  -->
                <div class="login_content col rounded-end bg-white d-flex align-items-center justify-content-center flex-column gap-5" >
                  <div class="text-start" v-if="!show_data_toke_user">
                    <h4>Il tuo link è scaduto torna a <router-link to="/">Login</router-link> </h4>
                    <!-- controllare se non esiste nome_user fare uscire un link di controlo  -->
               
                  </div>
                  <!-- FORM GROUP  -->
                  <div v-else>
                    <div v-if="isforgotPassword" >
                      <h5>Ciao {{ nome_user }}, inserisci una nuova password</h5>
                    </div>
                    <div v-else>
                      <h5> {{ nome_user }}, Splash ti dà il benvenuto!
                        <br>
                         Crea una password e tuffati nel mondo del People Analytics!</h5>
                    </div>
                    <div class="container-fluid  ">
                      
                      <form class="" @submit.prevent="RegisterNewPassword">
                            <div class="form_group">
                              <!-- <div  class="text-start ">
                                  <label for="password">Nuova password*</label>
                              </div> -->
                              <div class="d-flex gap-3 align-items-center">
                                  <input class="input_style" type="text" id="password" v-model="password" placeholder="Password"> <span v-if="confirmPassword && isPasswordMatch" class="green_color" > <font-awesome-icon icon="fa-solid fa-check " /> </span>
                              <div v-if="password_error" class="error-message"><small>Campo obbligatorio</small></div>
                              </div>
                              <div v-if="password && !isValidPassword" class="error-message text-start"><small>*La password deve contenere almeno 15 caratteri, 1 carattere speciale !@#$%^&*(), 1 lettera maiuscola, 1 lettera minuscola e 1 numero</small></div>
                            </div>
                            

                            <div class="form_group">
                                <!-- <div  class="text-start" >
                                    <label for="confirmPassword">Conferma Password*</label>
                                </div> -->
                                <div class="d-flex gap-3 align-items-center">
                                  <input class="input_style" :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" placeholder="Conferma Password">
                                  <span v-if="confirmPassword && isPasswordMatch" class="green_color">
                                      <font-awesome-icon icon="fa-solid fa-check" />
                                  </span>
                                  <div v-if="password_confermation_error" class="error-message">
                                      <small>Campo obbligatorio</small>
                                  </div>
                                  <div class="pointer" v-if="showPassword">
                                    <font-awesome-icon  icon="fa-solid fa-eye-slash" @click="showPassword = false" />
                                  </div>
                                  <div class="pointer" v-else>
                                    <font-awesome-icon  icon="fa-solid fa-eye" @click="showPassword = true" />
                                  </div>
                                  
                                </div>

                                <div v-if="confirmPassword && !isPasswordMatch" class="error-message text-start"><small>*Le password non corrispondono</small></div>
                        
                            </div>
                            <div class="errori text-start">
                              <p></p>
                            </div>
                            <div class="text-start fw-bolder ">
                              <small> La sicurezza del tuo account è fondamentale. <br>
                              La password scade dopo 180 giorni dalla sua generazione per proteggerti da potenziali minacce. 
                              Splash ti aiuterà a non dimenticarlo!
                              </small>
                            </div>
                            <div class="d-flex justify-content-between align-items-center gap-3 my-3">
                              <div @click="generatePassword" class="button-background-move height_edit">{{ $t('GeneratePw') }}</div>
                            
                              <div>
                                  <button type="submit" class="button-background-move" style="margin-top: 0px;">{{ $t('Save') }}</button>
                              </div>
                            
                            
                            </div>
                            
                        </form>
                        
                    </div>


                  </div>
               
                    
                    
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';
import LenguageSwithcer from '../components/nano/LenguageSwithcer.vue'
export default {
    components: { 
    
    LenguageSwithcer 
},
  data() {
    return {
      showPassword: false,
      show_data_toke_user :false,
      selectedGroupId:{}, //PER SALVARE L ID SELEZIONATO 
      nome_user: "",
      user_id:null,
      id_token_user:null,
      confirmPassword:'',
      password_confermation_error:false,
      username: '',
      username_error: false,
      email: '',
      email_error:false,
      password: '',
      cripted_password:'',
      password_error: false,
      groups:[],
      groups_error: false,
      new_account_id: null,

    };
  },
  computed: {
    isValidPassword() {
      
      const specialChars = '!@#$%^&*()';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const numbers = '0123456789';

      const hasSpecialChar = [...this.password].some(char => specialChars.includes(char));
      const hasUppercase = [...this.password].some(char => uppercaseLetters.includes(char));
      const hasLowercase = [...this.password].some(char => lowercaseLetters.includes(char));
      const hasNumber = [...this.password].some(char => numbers.includes(char));

      return hasSpecialChar && hasUppercase && hasLowercase && hasNumber;
    },
    isPasswordMatch() {
      return this.password === this.confirmPassword;
    },
    isValidForm() {

      return  this.isValidPassword && this.isPasswordMatch ;
      // 
    },
    isToken(){
      return this.$route.params.token;
    },
    isforgotPassword() {
    return this.isToken.includes("true");
    }
    
  },
  created(){
    this.fetchUserByToken()
    // console.log("token page changePassswrod:",this.isToken)
  },

  methods: {

    async fetchUserByToken(){
      const url = '/api/token_utente/?token=' + this.isToken;
        await axios.get(url)
          .then(response => {
            // console.log(response)
            // NELLA RISPOSTA ESTRAGGO ID DELL USER , PASSO L ID COME PARAMETRO PER CHIAMARE LA FUNZIONE fetchUserData NELLA QUALE RECUPERO I DATI DELL UTENTE 
            // console.log("risposta fetchUserBytoken user",response.data)
            if(response.data.length > 0){
              this.show_data_toke_user = true
              // eslint-disable-next-line no-unused-vars
              const id_token_user = response.data[0].id
              this.user_id = response.data[0].user
              this.id_token_user = response.data[0].id
              this.nome_user = response.data[0].username
              // console.log("id_token_user",id_token_user)
            } else {
              // console.log("vuoto")
            }
          
            
          })
          .catch(error => {
            console.error(error);
          }); 
    },

    // async fetchUserData(id_user){
    //   const url = '/api/gestisci_utente/' + id_user;
    //     axios.get(url)
    //       .then(response => {
    //         // NELLA RISPOSTA ESTRAGGO I DATI DELL UTENTE 
    //         // console.log(response.data.username)
    //         this.nome_user = response.data.username
            
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       }); 
    // },
    generatePassword() {
      const specialChars = '!@#$%^&*()';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const numbers = '0123456789';

      const randomChar = (characters) => {
        const randomIndex = Math.floor(Math.random() * characters.length);
        return characters[randomIndex];
      };

      const requiredChars = [
        randomChar(specialChars),
        randomChar(uppercaseLetters),
        randomChar(lowercaseLetters),
        randomChar(numbers)
      ];

      const remainingLength = 11; // Length of the password excluding required characters

      let password = requiredChars.join('');

      for (let i = 0; i < remainingLength; i++) {
        const allCharacters = specialChars + uppercaseLetters + lowercaseLetters + numbers;
        password += randomChar(allCharacters);
      }

      // Shuffle the password to randomize the positions of the required characters
      const shuffledPassword = password.split('').sort(() => Math.random() - 0.5).join('');
      
      
      this.password = shuffledPassword;
      // console.log("password generata dalla funzione GeneratePassword" )
      // console.log("password creata:",this.password)
      // // console.log("password criptata",this.cripted_password)

      
    },

    
    modifyPassword() {
          const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');

          // Dati da codificare
          const stringaOriginale = this.password; // Utilizza la password generata come stringa originale
          // console.log(stringaOriginale)
          // Codifica dei dati utilizzando AES
          const encryptedData = CryptoJS.AES.encrypt(stringaOriginale, key, {
            mode: CryptoJS.mode.ECB, // Modalità di crittografia
            padding: CryptoJS.pad.Pkcs7 // Padding
          }).toString();
          

          this.cripted_password = encryptedData
          // console.log(this.cripted_password)
          this.$swal.fire({
          title: 'Hai scelto una nuova Password!',
          text: 'Se sei sicuro della tua scelta noi lavoreremo per tenerti al sicuro! Clicca su Salva per confermare. ', 
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salva',
          cancelButtonText: 'Annulla'
          })
          .then(result => {
            // console.log(result);
            if (result.isConfirmed) {
              // event.target.submit()
             // Esegue la submit del form
              // console.log("password creata dopo salva:",this.password)
              // console.log("password criptata dopo salva",this.cripted_password)
              this.RegisterNewPassword()
              
             
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
              // console.log(error.response)
          // Gestisci gli errori o le eccezioni
          });
      },

 

    async RegisterNewPassword() {

      const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');

      // Dati da codificare
      const stringaOriginale = this.password; // Utilizza la password generata come stringa originale
      // console.log(stringaOriginale)
      // Codifica dei dati utilizzando AES
      const encryptedData = CryptoJS.AES.encrypt(stringaOriginale, key, {
        mode: CryptoJS.mode.ECB, // Modalità di crittografia
        padding: CryptoJS.pad.Pkcs7 // Padding
      }).toString();


      this.cripted_password = encryptedData
      // console.log(this.cripted_password)

      if (!this.isValidForm) {
      
        if(this.password == ''){
          // console.log("password non può essere un campo vuoto ")
          this.password_error = true
          setTimeout(() => {
            this.password_error = false
            
          }, 2000);

        }

        if(this.confirmPassword == ''){
          
          this.password_confermation_error = true
          setTimeout(() => {
            this.password_confermation_error = false
            
          }, 2000);

          
        }
        // console.log("Form non valido");
        return;
      }
      
      const formData = {
        password: this.cripted_password,
      };

      await axios
        .patch('/api/token_utente/' + this.id_token_user + '/', formData)
        .then(response => {
          // console.log(response);
          // console.log("password creata nel register:",this.password)
          // console.log("password criptata nel register",this.cripted_password)
          this.new_account_id = response.data.id;
        
          // console.log("Password modificata con successo");
          this.$swal('Password salvata con successo', '', 'success')
          // eslint-disable-next-line no-unused-vars
          .then(response =>{
            this.$router.push('/')
          })

          
          
          
        }) 
        .catch(error => {
          // console.log(error)
          this.$swal(error.response.data.error[0], '', 'error');
            // this.$swal(error.response.data.error[0], '', 'error');
            // // console.log(error.response.data.error[0]); // Dati di risposta del server
            // // console.log(error.response.status); // Codice di stato HTTP
            // // console.log(error.response.headers); // Intestazioni della risposta
        });
   
  },


    close() {
      // Emetti un evento "close" per chiudere il modulo
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>

  .form_container{
    width: 95%;
  }
.green_color{
  color: green;
}
.input_style{
    
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    margin: 15px 0px;
  }
.logo{
    width: 200px;
    // background: rgba($color: #ffffff, $alpha: 0.5);
}

.bg_image{
    background-image: url('./../assets/img/AskWithSplash.png');
    background-size:700px;
    background-position: right;
    background-repeat: no-repeat;
    // border-radius: 20px 0px 0px 20px ;
}
.login_container{
    height: calc(100vh )  ;
    .title_site{
        color: $hrcoffee-darkblue;
    }
}
.login_box{
        box-shadow: 3px 3px 8px 8px rgba(167, 164, 164, 0.55);
        border-radius: 10px;
        height: 90%;
        animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            .welcome_content{
                animation: leftEnter ease 0.7s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
            .login_content{
                animation: rightEnter ease 0.7s;
                animation-iteration-count: 1;
                // animation-fill-mode: forwards;
            }
    }
.input_style{
    width: 350px;
}

.error-message{
   color: red;
}

</style>
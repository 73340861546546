<!-- eslint-disable no-unused-vars -->
<template>
   <!-- COMPONENTE DI LOGIN -->
   <div>
        <div>
            <h2 class="title_site" ><b>{{$t('Login')}}</b></h2>
        </div>
    
        <form @submit.prevent="submitForm" class="d-flex flex-column mt-5 gap-4">
            <div class="mb-3">
                <!-- <label for="username" class="form-label">Username</label> -->
                <input type="text" name="username" v-model="username" class="form-control input_style" id="username" aria-describedby="emailHelp" placeholder="*Email" :class="{'error_class':error_status_user || error_400}">
                <!-- <p><small> Non hai inserito nessun username adsfasdf</small></p> -->
                <div v-if="error_status_user" class="error_message"><small>{{$t('EmptyUser')}}</small> </div>
                <div v-if="error_400" class="error_message"><small>{{$t('Error400UserPw')}}</small> </div>
            </div>
            
            <div class="mb-3">
                <!-- <label for="password" class="form-label">Password</label> -->
                <input type="password" name="password" v-model="password" class="form-control input_style" id="password" placeholder="*Password" :class="{'error_class':error_status_password || error_400}">
                <div v-if="error_status_password" class="error_message"><small>{{$t('EmptyPw')}}</small> </div>
                <div v-if="error_400" class="error_message"><small>{{$t('Error400UserPw')}}</small></div>
            </div>
            
            <div class="d-flex justify-content-center ">
                <button class="button_submit mt-3" type="submit"><b> {{$t('Access')}}</b></button>
            </div>
            
        </form>
    </div>
    <!-- FINE COMPONENTE DI LOGIN -->
</template>

<script>
import axios from 'axios'
import store from '@/store'
export default {
    name:'LoginComponent',
    data() {
        return{
            username:'',
            password:'',
            error_status_user: false,
            error_status_password : false,
            error_400 : false,
        }
    },   
    computed: {
      userId() {
        return store.state.userId; // Ottenere il valore dallo store tramite computed property
      }
    },

  
    methods:{

        /*
        * Questo codice definisce un metodo asincrono submitForm che 
        * invia una richiesta di tipo POST a '/api/v1/token/login' con 
        * lo username e la password provenienti dal form. 
        * Gestisce la risposta memorizzando il token nello store di Vuex, 
        * impostando l'intestazione di autorizzazione per le future richieste 
        * e quindi navigando alla homepage. In caso di errore, gestisce diversi 
        * casi come username o password vuoti, o un errore 400, aggiornando i flag 
        * di errore e cancellandoli dopo 2 secondi.
        */
        // eslint-disable-next-line no-unused-vars
        async submitForm(e){
            const formData = {
                username: this.username,
                password: this.password,
            }

            try {
                const response = await axios.post('/api/v1/token/login', formData);
                // console.log('response login:', response);
                const token = response.data.auth_token;
                this.$store.commit('setToken', token);
                axios.defaults.headers.common['Authorization'] = "Token " + token;
                // TODO reucperare il token diversamente per rimanere connessi 
                localStorage.setItem("token", token);
                await this.getUserDetails();
                this.fetchAziendaUser();
                this.$store.dispatch('GetValidateBeforeFile');
                this.$router.push('/');
            } catch (error) {
                this.error_status_password = false;
                this.error_status_user = false;
                this.error_400 = false;
                if (this.username === '' && this.password === '') {
                    this.error_status_user = true;
                    this.error_status_password = true;
                } else if (this.username === '') {
                    this.error_status_user = true;
                } else if (this.password === '') {
                    this.error_status_password = true;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    this.error_400 = true;
                }
                setTimeout(() => {
                    this.error_status_user = false;
                    this.error_status_password = false;
                    this.error_400 = false;
                }, 2000);
            }
        },

        /**
         * Recupera i dettagli dell'utente dal server e aggiorna 
         * lo store Vuex con le informazioni dell'utente.
         *
         * @param Nessuno
         * @return Nessuno
         */
        async getUserDetails() {
            try {
                const response = await axios.get('/api/v2/users/me/');
                const { email, username, id, groups } = response.data;
                const group = groups[0];
                this.$store.commit('setUserId', id);
                this.$store.commit('setEmail', email); 
                this.$store.commit('setUsername', username); 
                this.$store.commit('setUserGroup', group);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Recupera l'utente azienda dall'API e aggiorna lo stato locale con 
         * i dati della risposta.
         * 
         * @param {type} userId - l'ID dell'utente per cui recuperare i dati aziendali
         * @return {type} undefined
         */
        async fetchAziendaUser() {
            const userId = this.userId;
            try {
                const response = await axios.get('/api/azienda_user/?user=' + userId);
                this.aziendaVsUser = response.data;
                store.state.user_company_id = this.aziendaVsUser[0].azienda.id;
                store.state.gpt_activate = response.data[0].azienda.gpt_flag;
                // this.fetchAzienda()
                // Esegui altre operazioni con i dati ricevuti
            } catch (error) {
                console.error(error);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .error_class{
        border: 2px solid red;
    }
    .error_message{
        color: red;
    }

</style>
<template>
  <div class="" v-cloak>
    <div v-if="isChangePasswordRoute || isAbbonamentiRoute || isPagamentoRoute || isPagamentoSuccessRoute || isAbbonamentoScaduto" v-cloak>
      <router-view />
    </div>
    <div v-else>
      <!-- AUTENTICATO E PUò OPERARE  -->
      <div v-if="isAuthenticated " v-cloak>
        <!-- <BaseHeader/>
        <BaseMain/>
        <base-footer/> -->
        <page-structure/>
      </div>
      <!-- V ELSE IF , AUTENTICATO E NON PUò OPERARE  -->
   
      <div v-else>
        <!-- Se non autenticato chiama la pagina di login -->
        <log-in/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import store from './store';
import LogIn from './views/LogIn.vue';
import ChangePassword from './views/ChangePassword.vue'
import PageStructure from './components/macro/PageStructure.vue';
import AbbonamentoScadutocomponent from './components/micro/AbbonamentoScadutocomponent.vue';
// import BaseHeader from './components/macro/BaseHeader.vue';
// import BaseMain from './components/macro/BaseMain.vue';
// import BaseFooter from './components/macro/BaseFooter.vue';

export default{
  name: 'App',
  components:{
    LogIn,
    PageStructure,
    // eslint-disable-next-line vue/no-unused-components
    ChangePassword,
    // eslint-disable-next-line vue/no-unused-components
    AbbonamentoScadutocomponent,
    // BaseHeader,
    // BaseMain,
    // BaseFooter,
  },
  data() {
        return{
          // userId: null
          showChangePassword:false,
          token: null,
          pageTitle: "Splash"
        }
  }, 

  mounted() {
    if(store.state.isAuthenticated){

      // OTTENGO I DATI UTENTE SE AUTENTICATO
      // eslint-disable-next-line no-unused-vars
      this.getUserDetails().then(response => {
            // console.log(response)
            // CHIAMA LA FUNZIONE PER PRENDERE L AZIENDA ASSOCIATA ALL UTENTE 
            this.fetchAziendaUser()
            // IN QUESTA FUNZIONE NELLO STORE VERIFICO SE L UTENTE è REGISTRATO E DI CONSEGUENZA CONNESSO A ZUCCHETTI 
            this.$store.dispatch('zucchettiConnectionControl',{ userId: this.$store.state.userId });
            this.$store.dispatch('GetValidateBeforeFile');
            // this.$store.dispatch('zucchetttiExpiredSessionReload');
        });
    }
  
  },
  beforeMount() {
    // document.title = this.pageTitle;

    //  // Cambia la favicon
    // const newFavicon = '/nuova-favicon.ico'; // Percorso della nuova favicon
    // const oldFavicon = document.querySelector('link#icon');
    // // console.log("oldfavicon;",oldFavicon)

    // if (oldFavicon) {
    //   oldFavicon.href = newFavicon;
    // }
  },

  computed:{
    userId() {
      return store.state.userId;
    },
    username(){
      return store.state.username
    },
    email(){
      return store.state.email
    },
    isAuthenticated(){
      return store.state.isAuthenticated
    },
    CanOperate(){
      return store.state.validate_data_file.can_operate
    },
    isToken(){
      return this.$route.params.token;
    },
    isChangePasswordRoute() {
      // // console.log("kittemurt:", this.isToken)
      return this.$route.path === '/change-password/' + this.isToken;
    },
    isAbbonamentiRoute() {
      // // console.log("kittemurt:", this.isToken)
      return this.$route.path === '/subscription-page' ;
    },
    isPagamentoRoute() {
      // // console.log("kittemurt:", this.isToken)
      return this.$route.path === '/payment-page' ;
    },
    isPagamentoSuccessRoute() {
      // // console.log("kittemurt:", this.isToken)
      return this.$route.path === '/success-payment' ;
    },
    isAbbonamentoScaduto(){
      return this.$route.path === '/subscription-expired-page' ;
    }


  },
  methods: {
    async getUserDetails() {
      await axios
          .get('/api/v2/users/me/')
          .then(response => {
          // console.log("risposta get user detail",response.data)
          const email = response.data.email
          const username = response.data.username
          const userId = response.data.id;
          const group = response.data.groups[0]
          // console.log(group)
          this.$store.commit('setUserId', userId); // Utilizza una mutazione personalizzata nello store per assegnare l'ID dell'utente
          this.$store.commit('setEmail', email); 
          this.$store.commit('setUsername', username); 
          this.$store.commit('setUserGroup', group);
          })
          .catch(error => {
          console.error(error);
          });
    },
    async fetchAziendaUser() {
            const userId = this.userId
            await axios.get('/api/azienda_user/?user=' + userId)
            .then(response => {
                this.aziendaVsUser = response.data;
                // console.log("risposta azienda:",this.aziendaVsUser[0].azienda.id)
                store.state.user_company_id = this.aziendaVsUser[0].azienda.id
                // console.log("azienda:",response.data[0].azienda)
                store.state.gpt_activate = response.data[0].azienda.gpt_flag

                // this.fetchAzienda()
                // Esegui altre operazioni con i dati ricevuti
            })
            .catch(error => {
                console.error(error);
            });
        },
  },

  beforeCreate(){
    
    // console.log("beforecreate")
    this.$store.commit('initializeStore')

    const token = this.$store.state.token
    // console.log('token: ',token)
    if(token){
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  }
}
</script>

<style lang="scss">
// .general_bg{
//   background-image: url(./assets/img/general_background.jpg);
// }


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

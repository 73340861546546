import axios from 'axios';


// PER PRODUZIONE 
// axios.defaults.baseURL = "https://devbackend.hrcoffee.eu/"

// PER LOCALE 
// axios.defaults.baseURL = "http://127.0.0.1:8000/"

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token'); // Imposta l'header di autorizzazione (se necessario)
axios.defaults.headers.post['Content-Type'] = 'application/json'; // Imposta il tipo di contenuto per le richieste POST (se necessario)

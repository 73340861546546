<template>
    <div>
        <h5>{{ $t('ForgotEmail') }}</h5>
        <form class=" d-flex flex-column" @submit.prevent="SentEmail">
            <div class="form-group ">
                <label for="">*Email</label>
                <input  class="input_style mx-3" v-model="email" type="email">
            </div>
            <div class="my-4">
                <button type="submit" class="button-background-move" style="margin-top: 0px;">Invia</button>
            </div>
        
        </form>
    </div>
</template>
  
<script>
// eslint-disable-next-line no-unused-vars
import axios, { AxiosError } from 'axios'
export default {
    name: 'ForgotPwpage',
    data() {
    return {
        email : ""
    };
    
  },
  mounted(){

  },
  created(){
    
  },
  methods: {
        async SentEmail(){
            
            const formData = {
                email: this.email,
            };

            await axios
                .post('/api/token_utente/recupero_password/', formData)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                // // console.log(response);
                this.$swal('Abbiamo inviato una mail al seguente indirizzo '+ this.email + ' per il recupero password' , '', 'success');
                this.email = ""
                this.$emit('chiamataRiuscita'); 
                
                }) 
                .catch(error => {
                    this.$swal(error.response.data.error[0], '', 'error');
                    // console.log(error.response.data.error[0])
                    // console.log(error.response.data.error); // Dati di risposta del server
                    // console.log(error.response.status); // Codice di stato HTTP
                    // console.log(error.response.headers); // Intestazioni della risposta
                });
                
            },
    }
}

</script>
  
<style lang="scss" scoped>
.input_style{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    margin: 15px 0px;
  }
</style>
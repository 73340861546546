export default {
  "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome page"])},
  "WelcomeSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi per continuare"])},
  "HomePageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto! Scarica o carica i tuoi modelli e dai inizio all'analisi dei dati con un semplice Splash!"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciao i18n !!"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica modello"])},
  "downloadobj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
  "Italiano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "Inglese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
  "Spagnolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagnolo"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "Learning Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Analytics"])},
  "Learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning"])},
  "Competenze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenze"])},
  "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "Revisione salariale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione Salariale"])},
  "HelpDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HelpDesk"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Account"])},
  "Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
  "ForgotPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password ?"])},
  "EmptyUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Campo user vuoto"])},
  "EmptyPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Campo password vuoto"])},
  "Error400UserPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Username o password errata"])},
  "loadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica il file utilizzando il pulsante o trascinalo qui"])},
  "SelectFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o sleziona una cartella"])},
  "loadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento effettuato con successo !"])},
  "loadStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio caricamento record"])},
  "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In lavorazione"])},
  "errorLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante il caricamento"])},
  "Avanti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
  "JobFamilySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Job Family"])},
  "user40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti presenti da 40+ anni"])},
  "user35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti presenti da 35+ anni"])},
  "user30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti presenti da 30+ anni"])},
  "user20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti presenti da 20+ anni"])},
  "user5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti presenti da meno di 5 anni"])},
  "rangeHiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona range - data di assunzione"])},
  "SelectEmploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona dipendente"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "HrAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrAnalytics"])},
  "HrReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrReporting"])},
  "Archivio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "EditCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica company profile"])},
  "NewCompanyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca qui ed inserisci subito i tuoi dati"])},
  "NewCompanyProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca qui ed inserisci subito la tua company profile"])},
  "ManageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci utenti"])},
  "NewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo utente"])},
  "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
  "GeneratePw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera Password"])},
  "GptActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva ChatGpt"])},
  "GptDeactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva ChatGpt"])},
  "AllowGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Cliccando sulla checkbox acconsentirai all'utlizzo di ChatGPT "])},
  "BackToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla pagina di login"])},
  "ForgotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci email per recuperare la tua password"])},
  "privacyGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione per l'utilizzo del sistema Chat GPT"])},
  "privacyGptP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash ha per te un grande alleato! Abbiamo scelto di inserire l'innovativo sistema di Chat GPT per aiutarti a elaborare le descrizioni commentate relative ai tuoi grafici! Per queste ragioni, prenditi il tempo necessario per leggere attentamente i seguenti termini prima di procedere."])},
  "privacyGptP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sappiamo che la raccolta dei dati, l’elaborazione dei grafici, la loro descrizione e i report richiedono tempo ed energie preziose che solitamente vengono sottratte al tuo lavoro. Con Splash vogliamo offrirti le funzionalità chiave del People Analytics per aiutarti a interpretare i dati e prendere decisioni sulla gestione delle tue persone nel più breve tempo possibile, offrendo un vantaggio sia a te che ai tuoi collaboratori."])},
  "privacyGptList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni dato va custodito e processato con cura, per questo ci teniamo alla privacy e vogliamo darti tutte le informazioni utili al riguardo:"])},
  "privacyGptLi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovrai creare un account per utilizzare i tuoi dati"])},
  "privacyGptLi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei tu il detentore dei tuoi dati, pertanto la responsabilità degli stessi è vincolata all'utilizzo che sceglierai di farne sotto la tua responsabilità. Noi faremo solo ciò che è necessario affinché i nostri servizi funzionino correttamente."])},
  "privacyGptLi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di tanto in tanto potremmo apportare modifiche ai nostri termini, servizi e/o tariffe. "])},
  "privacyGptLi4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci teniamo a ricordarti che utilizzando il nostro servizio ti assumi la responsabilità di eventuali conseguenze imputabili al normale uso di Chat GPT "])},
  "privacyGptLi5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per i dettagli legali e informazioni specifiche puoi sempre leggere la pagina di  "])},
  "privacyGptWordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design di Splash."])},
  "Introduzione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzione"])},
  "IntroduzioneP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il presente documento descrive le misure di Privacy by Design adottate da Splash, un sistema di people analytics, al fine di garantire la protezione dei dati personali degli utenti nell'utilizzo delle API di ChatGPT. Splash si impegna a rispettare la privacy degli utenti e a conformarsi alle normative sulla protezione dei dati, ponendo al centro la sicurezza e il controllo dell'utente admin."])},
  "IntroduzioneP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come sistema di people analytics, Splash consente alle aziende di ottenere analisi dettagliate e utili sulle informazioni relative al personale, fornendo una panoramica completa e approfondita delle dinamiche aziendali. In tale contesto, l'utilizzo delle API di ChatGPT rappresenta un valore aggiunto per le aziende, consentendo di generare commenti e report in modo rapido ed efficace."])},
  "IntroduzioneP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuttavia, consapevole dell'importanza della privacy e della necessità di garantire un trattamento dei dati personali conforme alle normative vigenti, Splash ha adottato misure di Privacy by Design, che integrano le migliori pratiche di privacy nel processo di progettazione e implementazione del sistema."])},
  "IntroduzioneP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel seguito del documento, saranno illustrate le misure adottate da Splash per garantire la privacy dei dati personali, in linea generale e in relazione a specifiche aree come i sistemi IT, le pratiche commerciali corrette e la progettazione strutturale e le infrastrutture di rete. Saranno inoltre approfondite le sette principali aree di Privacy by Design, che comprendono l'approccio proattivo, la privacy come impostazione di default, l'integrazione della privacy nella progettazione, la massima funzionalità, la sicurezza fino alla fine, la visibilità e trasparenza e il rispetto per la privacy dell'utente."])},
  "IntroduzioneP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attraverso l'implementazione di tali misure, Splash si impegna a fornire una piattaforma di people analytics sicura, funzionale e rispettosa della privacy, in cui l'utente admin ha il controllo sul trattamento dei dati personali e può prendere decisioni informate e consapevoli riguardo ai limiti temporali di archiviazione dei dati e all'utilizzo di ChatGPT."])},
  "PrivacyByDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design in Splash"])},
  "PrivacyByDesignP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash è una piattaforma di people analytics progettata per consentire alle aziende di ottenere informazioni approfondite e significative sulle dinamiche del personale. Attraverso l'utilizzo di avanzate tecnologie analitiche, Splash raccoglie e analizza dati relativi ai dipendenti, consentendo alle aziende di prendere decisioni informate e di migliorare le performance organizzative. "])},
  "PrivacyByDesignP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuttavia, Splash comprende l'importanza della privacy e dell'anonimizzazione dei dati personali. "])},
  "PrivacyByDesignP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In un mondo sempre più connesso, è fondamentale garantire che le informazioni sensibili dei dipendenti siano trattate in modo sicuro e che vengano adottate misure adeguate per proteggere la privacy di ciascun individuo.  "])},
  "PrivacyByDesignP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tal fine, Splash adotta un approccio basato su Privacy by Design, un concetto che integra la privacy fin dall'inizio del processo di progettazione del sistema. "])},
  "PrivacyByDesignP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ciò significa che la privacy viene considerata come un elemento centrale sin dalle fasi iniziali di sviluppo di Splash, anziché essere un'aggiunta successiva o reattiva.  "])},
  "PrivacyByDesignP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Questo approccio garantisce che la privacy sia incorporata nel sistema fin dalla sua concezione, proteggendo i dati personali degli utenti in modo proattivo."])},
  "PrivacyByDesignP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash implementa misure di sicurezza avanzate per proteggere i dati personali degli utenti. "])},
  "PrivacyByDesignP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sono adottate tecnologie e protocolli crittografici per garantire la sicurezza dei dati in transito e a riposo.  "])},
  "PrivacyByDesignP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Inoltre, vengono implementati controlli di accesso e autenticazione per limitare l'accesso ai dati solo agli utenti autorizzati.  "])},
  "PrivacyByDesignP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I sistemi IT utilizzati da Splash vengono costantemente monitorati e aggiornati per mantenere un livello elevato di sicurezza. "])},
  "PrivacyByDesignList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Di seguito vengono illustrate le sette principali aree di Privacy by Design: "])},
  "privacyTitleList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratiche commerciali corrette"])},
  "privacyTitleList1P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash si impegna a condurre le proprie attività commerciali in conformità alle normative sulla protezione dei dati. Le politiche e le pratiche aziendali di Splash sono progettate per garantire la riservatezza e la sicurezza dei dati personali degli utenti. Vengono adottate misure per evitare la divulgazione non autorizzata o l'uso improprio dei dati e per garantire che le informazioni personali siano trattate in modo lecito e trasparente."])},
  "privacyTitleList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progettazione Strutturale e Infrastrutture di rete"])},
  "privacyTitleList2P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integra la privacy nella progettazione strutturale e nelle infrastrutture di rete. Sono adottate misure tecniche e organizzative per garantire che i dati personali siano protetti da accessi non autorizzati o da usi impropri. Vengono implementati firewall, monitoraggio dei log e controlli di sicurezza per prevenire violazioni della sicurezza e garantire la confidenzialità e l'integrità dei dati."])},
  "privacyTitleList3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proattivo non reattivo – prevenire non correggere"])},
  "privacyTitleList3P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash adotta un approccio proattivo alla privacy dei dati, cercando di prevenire potenziali violazioni o problemi anziché affrontarli in modo reattivo. Vengono implementate misure di sicurezza e politiche di gestione dei dati per identificare e mitigare i rischi per la privacy fin dalle prime fasi di sviluppo e implementazione del sistema di people analytics di Splash."])},
  "privacyTitleList4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy come impostazione di default"])},
  "privacyTitleList4P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash configura il sistema di people analytics in modo tale che la privacy sia impostata come valore predefinito. Vengono adottate misure tecniche e organizzative per garantire che i dati personali siano protetti sin dall'inizio e che l'utente debba fornire il consenso esplicito per eventuali operazioni di trattamento dei dati che superano le finalità previste."])},
  "privacyTitleList5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy incorporata nella progettazione"])},
  "privacyTitleList5P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integra la privacy nella progettazione del sistema di people analytics. Sono adottate misure per ridurre al minimo l'utilizzo di dati personali e identificativi, in modo da escluderne il trattamento quando possibile. Vengono implementati meccanismi di pseudonimizzazione e anonimizzazione dei dati per proteggere l'identità degli utenti, garantendo al contempo la validità delle analisi e dei report generati."])},
  "privacyTitleList6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massima funzionalità - Valore positivo, non valore zero"])},
  "privacyTitleList6P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash si impegna a offrire un'esperienza funzionale e di valore per gli utenti, senza compromettere la privacy. Vengono implementate funzionalità che consentono agli utenti di ottenere analisi dettagliate e utili senza compromettere la riservatezza dei dati personali. Sono adottate misure per garantire che l'elaborazione dei dati avvenga nel rispetto delle normative sulla privacy e con la massima accuratezza e pertinenza."])},
  "privacyTitleList7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza fino alla fine - Piena protezione del ciclo vitale"])},
  "privacyTitleList7P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash garantisce la sicurezza dei dati personali per l'intero ciclo di vita del sistema di people analytics. Vengono implementate misure di sicurezza per proteggere i dati durante la raccolta, l'archiviazione, l'elaborazione e la trasmissione. Vengono adottati protocolli di eliminazione sicura per garantire la cancellazione definitiva dei dati personali quando non sono più necessari o richiesti."])},
  "privacyTitleList8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità e trasparenza - Mantenere la trasparenza"])},
  "privacyTitleList8P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash si impegna a mantenere la trasparenza riguardo alle proprie politiche e pratiche in materia di privacy. Vengono fornite informazioni chiare e comprensibili agli utenti riguardo alla gestione dei loro dati personali, inclusi i tipi di dati raccolti, le finalità del trattamento, i diritti degli utenti e le misure di sicurezza adottate. Inoltre, Splash si impegna a fornire meccanismi di controllo e consenso all'utente admin, in modo che possa prendere decisioni informate riguardo al trattamento dei dati personali."])},
  "privacyTitleList9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispetto per la privacy dell'utente - Centralità dell'utente"])},
  "privacyTitleList9P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash pone al centro il rispetto per la privacy dell'utente. Vengono adottate misure per garantire che i dati personali siano trattati in modo lecito, equo e trasparente, nel rispetto delle preferenze e dei diritti degli utenti. Vengono forniti meccanismi per l'esercizio dei diritti degli utenti, come il diritto di accesso, di rettifica e di cancellazione dei dati personali."])},
  "Conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusioni"])},
  "ConclusionP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash implementa misure di Privacy by Design nel sistema di people analytics per garantire la protezione dei dati personali degli utenti nell'utilizzo delle API di ChatGPT. "])},
  "ConclusionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attraverso l'adozione di approcci proattivi, l'incorporazione della privacy nella progettazione, l'impiego di politiche e pratiche commerciali corrette, e l'attenzione alla sicurezza, Splash si impegna a fornire una piattaforma funzionale, sicura e rispettosa della privacy."])},
  "ConclusionP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash si impegna a fornire all'utente admin gli strumenti necessari per gestire e controllare l'archiviazione dei dati e l'utilizzo di ChatGPT."])},
  "ConclusionP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Attraverso un'interfaccia intuitiva, l'utente admin può facilmente impostare i limiti temporali di archiviazione dei dati e attivare o disattivare l'utilizzo di ChatGPT. "])},
  "ConclusionP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo garantisce all'utente admin un pieno controllo sul trattamento dei dati personali e consente di adattare il sistema di Splash alle esigenze aziendali e alle politiche interne sulla conservazione dei dati."])},
  "ConclusionP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel rispetto dei principi di Privacy by Design, Splash si impegna a garantire che la raccolta, l'elaborazione e la conservazione dei dati personali avvengano in modo sicuro e conforme alle normative vigenti. "])},
  "ConclusionP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'anonimizzazione dei dati rappresenta una componente fondamentale della strategia di privacy adottata da Splash. "])},
  "ConclusionP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono applicate tecniche di anonimizzazione per proteggere l'identità degli utenti, sostituendo dati identificativi con identificatori unici o aggregando i dati in modo che non sia possibile identificare individualmente gli utenti. "])},
  "ConclusionP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo assicura che le analisi e i report generati da Splash siano basati su dati anonimizzati, riducendo al minimo il rischio di divulgazione di informazioni personali sensibili."])},
  "ConclusionP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash si impegna a monitorare costantemente l'efficacia delle misure di Privacy by Design implementate, al fine di identificare e affrontare eventuali vulnerabilità o rischi per la privacy. "])},
  "ConclusionP11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono condotte valutazioni periodiche della sicurezza dei sistemi e delle procedure di gestione dei dati per garantire un'adeguata protezione delle informazioni personali degli utenti."])},
  "ConclusionP12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash, come sistema di people analytics, adotta misure di Privacy by Design per garantire la protezione dei dati personali degli utenti nell'utilizzo delle API di ChatGPT. L'utente admin ha il controllo su limiti temporali di archiviazione dei dati e l'utilizzo di ChatGPT, consentendo una gestione flessibile e personalizzata dei dati e delle interazioni con il sistema. "])},
  "ConclusionP13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalsh si impegna a garantire la trasparenza, la sicurezza e il rispetto per la privacy dell'utente admin, assicurando che le decisioni riguardanti la gestione dei dati personali siano prese in modo consapevole e informato."])},
  "commentAllChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenta tutti i grafici con chatGPT"])},
  "AllChartAreCommented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i grafici hanno già un commento"])},
  "AnonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati in Anonimo"])},
  "DeanonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati Visibili"])},
  "GptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimento ChatGpt"])},
  "WriteReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi il tuo report"])},
  "loadMessageSpalsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi un attimo per il tuo splash"])},
  "maxChartSingleReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["massimo 5000 caratteri. Caratteri rimanenti:"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "ElaborateGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione grafico in corso"])},
  "ErrorUpdateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo spiacenti ! c è stato un errore nella modifica del report"])},
  "ErrorReportGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo spiacenti ! c è stato un errore nella generazione del report"])},
  "GenerateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera Report"])},
  "GenerateReportMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per mostrare il report genera tutti report singoli"])},
  "FileDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File eliminato con successo!"])},
  "optionGraphTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impostazioni grafico"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu: "])},
  "Gpt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max: "])},
  "clean_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulisci chat"])},
  "hi_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciao sono la tua AI,"])},
  "ask_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiedimi quello che vuoi! "])},
  "content_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso aiutarti  a generare immagini, testi, presentazioni e tanto altro in qualunque settore di tuo interesse"])},
  "try_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettimi alla prova! "])},
  "insert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci testo"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "GraphOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni grafico"])},
  "Legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legenda"])},
  "ChangeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia colore"])},
  "ColorQuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi procedere con la modifica del colore del grafico?"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun cambiamento effetuato"])},
  "colorChangeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il colore del grafico è stato aggiornato con successo. "])},
  "colorChangeNoSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci dispiace, ma la modifica del colore del grafico non è riuscita. "])},
  "organization_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità organizzativa"])},
  "info_unitOrg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa schermata fornisce una panoramica dettagliata dei grafici relativi alle distribuzioni di ciascuna variabile, suddivisi per le diverse unità organizzative."])},
  "info_unitOrg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui potrai accedere a informazioni esaustive che illustrano visivamente la distribuzione dei dati, consentendoti una comprensione approfondita delle caratteristiche di ogni variabile all'interno di ciascuna unità."])},
  "info_unitOrg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie a questa funzione, avrai a disposizione una visione chiara e dettagliata delle dinamiche organizzative, facilitando il processo decisionale e il monitoraggio della tua azienda."])},
  "elaboration_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione grafici in corso"])},
  "intro_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione introduzione report"])},
  "correlations_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione correlazione dati"])},
  "conclusion_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione conclusione"])},
  "New_question_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo suggerimento ChatGpt"])},
  "info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Solo un veloce heads-up:<br><br>Prima di tuffarti nella creazione del report generale, assicurati di aver generato i report per ogni singolo grafico."])},
  "delete_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicuro di voler eliminare il file pdf "])},
  "info_no_change_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pdf non è stato eliminato "])},
  "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente!"])},
  "info_load_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante il caricamento dei file e dei grafici, si consiglia di non navigare tra le pagine, al fine di garantire un processo fluido e completo"])},
  "salary_archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Revisione salariale"])},
  "elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report in elaborazione"])},
  "info_elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["( E' consigliato attendere il completamento del processo prima di cambiare pagina )"])},
  "info_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea il tuo report con un semplice clic sul pulsante PDF!<br><br> Una volta generato, troverai i tuoi documenti nell'area HR Reporting.<br><br>  Se non sei soddisfatto del report generato, puoi premere nuovamente il pulsante 'Suggerimento ChatGPT' per generarne uno nuovo."])},
  "Revisione_salariale_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione_salariale_report"])},
  "elaborating_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo creando una magia grafica su Splash!"])},
  "waiting_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci vorrà solo un attimo!"])},
  "noPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun pdf presente."])},
  "noPdf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per immergerti nel mondo dell'HRreporting, fai un salto nell'area desiderata, clicca su 'Report' e genera il tuo PDF!"])},
  "option_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni selezionate"])},
  "expiration_pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza delle password:"])},
  "expiration_archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza Archivi:"])},
  "expiration_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza File:"])},
  "status_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato ChatGpt:"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "disattivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattivo"])},
  "employee_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero dipendenti:"])},
  "select_uo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona ID Company"])},
  "totale_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale distribuzioni :"])},
  "name_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Pdf"])},
  "type_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo area"])},
  "name_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome File"])},
  "data_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Creazione"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "gpt_module_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di attivazione ChatGpt"])},
  "help_desk_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia le tue informazioni e noi ti contatteremo al più presto"])},
  "name_and_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo nome e cognome *"])},
  "email_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua email aziendale *"])},
  "request_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci la tua richiesta *"])},
  "privacy_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acconsento al trattamento dei miei dati e dichiaro di aver preso visione della Privacy Policy. "])},
  "anonymation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti informiamo che quando passi dallo stato anonimo allo stato visibile, i tasti 'Suggerimento Chatgpt' verranno disabilitati per garantire la tua privacy."])},
  "updateGraphApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna dati"])},
  "lastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo accesso"])},
  "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connesso"])},
  "performanceArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio Performance"])},
  "performanceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Performance"])},
  "skillsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Competenze"])},
  "totalRegression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale regressioni"])},
  "totalPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale performance"])},
  "totalPotential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale potenziale"])},
  "totalSalaryReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale revisione salariale"])},
  "regressionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa schermata fornisce una panoramica dettagliata dei grafici relativi alle correlazioni e alle regressioni tra variabili.<br><br>Qui troverai una serie di grafici e strumenti dedicati all'analisi delle relazioni tra variabili all'interno del contesto aziendale. Le regressioni sono uno strumento potente per comprendere come le diverse variabili si influenzino tra di loro, identificando il trend generale e valutando la forza e la direzione della correlazione.<br><br>Grazie a questa funzione potrai esplorare le relazioni tra le variabili per comprendere meglio l'andamento dei dati. Valutare l'impatto delle variabili sul risultato desiderato.<br><br>Utilizza i risultati per prendere decisioni informate e ottimizzare le strategie aziendali."])},
  "performanceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa visualizzazione offre una dettagliata panoramica dei grafici relativi alla performance aziendale.<br><br> Qui troverai una serie di grafici e strumenti dedicati all'analisi delle relazioni tra variabili nell'ambito delle performance aziendali.<br><br> uesti strumenti sono fondamentali per comprendere come diverse variabili influenzino le performance complessive, permettendoti di identificare i trend generali.<br><br>Attraverso questa funzione, puoi esplorare lo stato delle variabili per ottenere una migliore comprensione delle dinamiche di performance e valutare l'impatto di ciascuna variabile sul raggiungimento dei risultati desiderati.<br><br>I risultati ottenuti possono essere utilizzati per prendere decisioni informate e ottimizzare le strategie aziendali in termini di performance."])},
  "potentialInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa rappresentazione fornisce una panoramica dettagliata dei grafici relativi al potenziale di performance.<br><br>Qui avrai accesso a una serie di grafici e strumenti dedicati all'analisi delle relazioni tra variabili nel contesto del potenziale rispetto alla performance.<br><br>Questi strumenti sono fondamentali per comprendere come diverse variabili influenzino il potenziale complessivo dell'azienda, consentendoti di individuare i trend generali. Attraverso questa funzionalità, potrai esplorare lo stato delle variabili per ottenere una comprensione più approfondita delle dinamiche del potenziale aziendale e valutare l'impatto di ciascuna variabile nel raggiungimento dei risultati desiderati.<br><br>I risultati ottenuti possono essere sfruttati per prendere decisioni informate e ottimizzare le strategie aziendali per massimizzare il potenziale aziendale."])},
  "salaryReviewInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa visualizzazione fornisce una panoramica dettagliata dei grafici relativi alla revisione salariale. Qui avrai accesso a una serie di grafici e strumenti dedicati all'analisi delle relazioni tra variabili che contribuiscono alla revisione salariale. Questi strumenti sono fondamentali per comprendere come diverse variabili influenzino la revisione salariale dell'azienda, consentendoti di individuare i trend generali. Attraverso questa funzionalità, potrai esplorare lo stato delle variabili per ottenere una comprensione più approfondita delle dinamiche della revisione salariale e valutare l'impatto di ciascuna variabile nel raggiungimento dei risultati desiderati. I risultati ottenuti possono essere sfruttati per prendere decisioni informate e ottimizzare le strategie aziendali per massimizzare la revisione salariale."])},
  "infoJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa schermata offre una visione dettagliata tramite grafici sulle valutazioni medie delle competenze, suddivise per Job Title.<br><br>Qui troverai informazioni chiare e visuali sullo stato delle competenze, consentendoti di comprendere appieno le valutazioni associate alle competenze per ciascun Job Title.<br><br>Grazie a questa funzione, avrai accesso a una panoramica completa e dettagliata delle valutazioni, semplificando il processo decisionale e il monitoraggio del capitale umano"])}
}
<template>
    <div v-cloak>
        <section class=" login_container d-flex justify-content-center align-items-center " >
            <div class="login_box row gx-0 w-100 mx-4 ">
                <!-- COL WELCOME  -->
                <div class="welcome_content col rounded-start d-flex flex-column justify-content-between bg_image">
                    <div class="m-3 d-flex justify-content-between">
                        <img src="../assets/img/logo.png" class="logo" alt="">
                        <!-- <h1 class="title_site">SPLASH.In <font-awesome-icon icon="fa-solid fa-chart-line" /></h1> -->
                        <div >
                            <lenguage-swithcer/>
                        </div>
                        
                    </div>
                    <div class="d-flex flex-column align-items-end title_site mx-3">
                        <!-- <h1><b>{{$t('Welcome')}}</b> </h1>
                        <h5>{{$t('WelcomeSubtitle')}}</h5> -->
                    </div>
                    <div class="d-flex justify-content-start mx-4">
                        <p><b>Powered by Hrcoffee</b> </p>
                    </div>
                </div>
                <!-- COL LOGIN  -->
                <div class="login_content col rounded-end bg-white d-flex align-items-center justify-content-center flex-column gap-5">
                    <div v-if="login">
                        <div>
                            <login-component/>
                        </div>
                        
                        <div class="my-5 forgot_pw" @click="login = false">
                            
                            <p>{{$t('ForgotPw')}}</p>
                            
                        </div>

                    </div>
                    <div v-else>
                        
                        <forgot-pwpage @chiamataRiuscita="handleChiamataRiuscita"/>

                        <div class="my-5 back_login" @click="login = true">
                            
                            <p>{{ $t('BackToLogin') }}</p>
                            
                        </div>

                    </div>
                    
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import LoginComponent from '../components/micro/LoginComponent.vue'
import LenguageSwithcer from '../components/nano/LenguageSwithcer.vue'
import ForgotPwpage from './ForgotPwpage.vue'
export default {
  components: { 
    LoginComponent,
    LenguageSwithcer,
    ForgotPwpage 
},
    name:'LogIn',
    
    data() {
        return{
            username:'',
            password:'',
            login:true
        }
    },   
    methods:{
        handleChiamataRiuscita() {
            this.login = true; // Imposta la variabile "login" a true nel componente padre
        },
            // eslint-disable-next-line no-unused-vars
        submitForm(e){
            const formData = {
                username: this.username,
                password: this.password,
            }

            axios
                .post('/api/v1/token/login',formData)
                .then(response => {
                    
                    const token = response.data.auth_token
                    
                    // console.log("token:", token)

                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    localStorage.setItem("token", token)
                    this.$router.push('/home-view')
                    // // console.log(response)
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    // console.log(error)
                })
        },
        
        
    }

}
</script>

<style lang="scss" scoped>

.forgot_pw,.back_login{
    cursor: pointer;
    color: #243e6e;
    transition: 0.2s;
}

.forgot_pw:hover,.back_login:hover{
    cursor: pointer;
    color: #5e86d0;
}
.logo{
    width: 200px;
    // background: rgba($color: #ffffff, $alpha: 0.5);
}

.bg_image{
    background-image: url('./../assets/img/AskWithSplash.png');
    background-size:700px;
    background-position: right;
    background-repeat: no-repeat;
    // border-radius: 20px 0px 0px 20px ;
}
.login_container{
    height: calc(100vh )  ;
    .title_site{
        color: $hrcoffee-darkblue;
    }
}
.login_box{
        box-shadow: 3px 3px 8px 8px rgba(167, 164, 164, 0.55);
        border-radius: 10px;
        height: 90%;
        animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            .welcome_content{
                animation: leftEnter ease 0.7s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
            .login_content{
                animation: rightEnter ease 0.7s;
                animation-iteration-count: 1;
                // animation-fill-mode: forwards;
            }
    }
.input_style{
    width: 350px;
}

</style>
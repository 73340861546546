<template>
  <section class="" >
    
    <div class="side_bar_left  " :class="{'side_md': shouldDisplayIcon, 'side_sm': !shouldDisplayIcon}" :style="{ 'background-color': sidebarBackgroundColor }">
        <div>
            <!-- <h1>SIDE BAR</h1>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione explicabo alias consequuntur! Praesentium vitae perferendis qui, placeat maxime sint distinctio nemo quos veniam, non saepe officia natus, ut id libero.</p> -->
            <div>
              <!-- {{ navBarStatus }} -->
              <div @click="ShowNavBar" v-if="navBarStatus" class="d-flex justify-content-end m-3 pointer" >
                    <font-awesome-icon icon="fa-solid fa-xmark" class="font_awesome_style" />
              </div >
            </div>
            <nav class="mt-3" >
              <ul class="d-flex flex-column gap-3 px-2">
                <li class="d-flex align-items-center gap-2" v-for="(link, index) in links" :key="index" :class="{ active: $route.path === link.url, 'justify-content-center': !shouldDisplayIcon }" @click="topOfPage, changeSidebarColor(link.color) ">
                    
                  <router-link :to="link.url" class=" router_link " >
                    <div class="">
                      <font-awesome-icon :icon="link.icon" class="font_awesome_style" />
                    </div>
                    <div v-if="shouldDisplayIcon">
                      {{$t(link.name)}}
                    </div>
                  
                  </router-link>
                </li>
              </ul>
              
            </nav>
            
        </div>
        
    </div>
  </section>
</template>

<script>

import store from './../../store'
export default {
    name: 'sideBarLeft',
    data() {
            return{
            
              links: [
                { icon:'fa-solid fa-house', name: 'Home', url: '/' ,color:'#5398d3'},
                // { icon:'fa-solid fa-file', name: 'Files', url: '/files-page' },
                {icon:'fa-solid fa-chart-line', name: 'Learning Analytics', url: '/learning-analytics-page',color: '#534DA7' },
                {icon:'fa-solid fa-chart-simple', name: 'Competenze', url: '/skills-page' ,color: '#209BD7'},
                {icon:'fa-solid fa-arrow-trend-up', name: 'Performance', url: '/performance-page' ,color: '#64CE86'},
                { icon:'fa-solid fa-cash-register',name: 'Revisione salariale', url: '/salary-review-page',color: '#F0900B' },
                { icon:'fa-solid fa-chart-pie', name: 'HrAnalytics', url: '/hr-analytics-page' ,color: '#3E6EE7'},
                { icon:'fa-solid fa-file-pdf', name: 'HrReporting', url: '/hr-reporting-page' ,color: '#5398d3'},
                // { icon:'fa-solid fa-box-archive', name: 'Archivio', url: '/archivio-page',color: '#5398d3' },
                { icon:'fa-solid fa-user', name: 'Account', url: '/accounting-page',color: '#5398d3' },
                { icon:'fa-solid fa-puzzle-piece',name: 'Integration', url: '/integration',color: '#5398d3' },
                { icon:'fa-solid fa-house',name: 'HelpDesk', url: '/help-desk',color: '#5398d3' },
                
              ],    
              n_md:900,
              shouldDisplayIcon: false,
              sidebarBackgroundColor: ''
            }
    }, 
    created() {
    window.addEventListener('resize', this.updateShouldDisplayIcon);
    this.updateShouldDisplayIcon();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateShouldDisplayIcon);
    },
    mounted() {
      const storedColor = localStorage.getItem('sidebarColor');
      if (storedColor) {
        this.sidebarBackgroundColor = storedColor;
      }
    },
    methods:{
        ShowNavBar(){
            this.$store.commit('ShowNav');
        },
        topOfPage(){
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Per uno scrolling fluido, altrimenti "auto" per uno scrolling istantaneo
          })
        },
        updateShouldDisplayIcon() {
          this.shouldDisplayIcon = window.innerWidth >= this.n_md;
        },
        changeSidebarColor(color) {
          this.sidebarBackgroundColor = color;
          localStorage.setItem('sidebarColor', color);
        }
        

    },
    computed:{
        navBarStatus(){
          return store.state.nav_bar_status
        },
    },
}
</script>

<style lang="scss" scope>
.side_md{
  width: 200px;
}
.side_sm{
  width: 50px;
}
  .side_bar_left{
    // width: 13%;
    height: 100vh;
    background-color: $sidebar-background-color;
    color: $hrcoffee-text-primary;
    overflow-y: auto;
    position: fixed;
    z-index: 10;
    transition: 0.7s;

    .router_link{
      color: $hrcoffee-text-primary;
      text-decoration: none;
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }
  // .fade_in { animation: fadeIn 1s; }
  // .fade_in_page { animation: fadeIn 0.5s; }

  // @keyframes fadeIn {
  //     0% { opacity: 0; }
  //     100% { opacity: 1; }
  // }

  li {
    position: relative;
    display: inline-block;
   
  } 

  li::after {
    content: "";
    display: block;
    position: absolute;
    // left: 8px;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: $hrcoffee-text-primary;
    transition: width 0.3s;
  }

  li:hover::after {
    width:90%;

  }

  li.active::after {
    width: 90%;
    font-weight: bold;
   
  }



</style>
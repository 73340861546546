/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */

// FREE SOLID 
import { faCheck, faPuzzlePiece, faUsers } from '@fortawesome/free-solid-svg-icons' /*<font-awesome-icon icon="fa-solid fa-check" />*/
import { faChartLine, faChartSimple} from '@fortawesome/free-solid-svg-icons'/*<font-awesome-icon icon="fa-solid fa-chart-line" />*/

import { faCashRegister,faArrowTrendUp, faBoxArchive, 
            faFilePdf ,faChartPie, faArrowDown, faPenToSquare, 
            faTrash, faPaperPlane,faCaretDown,faUser,faEye,faEyeSlash,faFileExcel,faCircleXmark,faArrowLeft,faChevronDown, faCircleCheck, faGears,
            faPalette,faCommentDots,faEraser,faChevronLeft,faChevronRight,faFloppyDisk,faCircleInfo
        } from '@fortawesome/free-solid-svg-icons'/*<font-awesome-icon icon="fa-solid fa-cash-register" />*/

import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'/* <font-awesome-icon icon="fa-solid fa-download" /> */
import { faUpload } from '@fortawesome/free-solid-svg-icons'

// FREE BRANDS 
import { faFacebook } from '@fortawesome/free-brands-svg-icons' /* <font-awesome-icon icon="fa-brands fa-facebook" /> */
import { faFacebookF } from '@fortawesome/free-brands-svg-icons' /* <font-awesome-icon icon="fa-brands fa-facebook" /> */
import { faInstagram } from '@fortawesome/free-brands-svg-icons' /* <font-awesome-icon icon="fa-brands fa-instagram" /> */
import { faLinkedin } from '@fortawesome/free-brands-svg-icons' /*<font-awesome-icon icon="fa-brands fa-linkedin" />*/
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons' /*<font-awesome-icon icon="fa-brands fa-linkedin" />*/

// import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'/*<font-awesome-icon icon="fa-solid fa-arrow-trend-up" />*/
/* add icons to the library */
library.add(faBars,faUsers, faXmark, faHouse,faFilePdf,faChartPie,faArrowDown,faTrash, 
            faFile,faPaperPlane,faDoorOpen,faPenToSquare, faDownload, faUpload, faFacebook, 
            faTrash, faPaperPlane,faCaretDown,faUser,faEye,faEyeSlash,faFileExcel,faCircleXmark,faArrowLeft,
            faInstagram,faLinkedin,faFacebookF, faLinkedinIn,faCheck,faChartLine,faChartSimple,
            faCashRegister,faArrowTrendUp,faBoxArchive,faCaretDown,faUser,faEye,faEyeSlash,faFileExcel,faCircleXmark,faChevronDown,
            faCircleCheck,faGears,faPalette,faCommentDots,faEraser,faChevronLeft,faChevronRight,faFloppyDisk,faCircleInfo,faPuzzlePiece
            )
<template>

    <div >
        <div class="row gx-0">
            <!-- SIDEBAR -->
            <Transition name="slide-fade">
                <div class=" width_important gx-0 " v-if="navBarStatus" >
                    <div class="">
                        <sideBarLeft/>
                    </div>
                </div>
            </Transition>
            
            <div class="col gx-0 d-flex flex-column ">
                <!-- HEADER-->
                <div class=" d-flex align-items-center fixed_header " v-bind:class="{ 'fixed_header_expanded': navBarStatus && !shouldDisplayIcon, 'fixed_header_collapsed': !navBarStatus, 'fixed_header_expanded_md': navBarStatus && shouldDisplayIcon }" >
                    <div class="flex-grow-1">
                        <base-header/>
                    </div>
                </div>
                <!-- MAIN-FOOTER -->
                <div class="flex-grow-1 " :class="{ 'main_expanded_sm': navBarStatus && !shouldDisplayIcon, 'main_collapse': !navBarStatus, 'main_expanded_md': navBarStatus && shouldDisplayIcon }  " >
                    <base-main/>
                </div>
            </div>
        </div>

    </div>

    <!-- <sideBarLeft/>
    <base-header/>
    base-main/>  -->

</template>

<script>
import store from './../../store'
import BaseHeader from './BaseHeader.vue'
import BaseMain from './BaseMain.vue'
// import BaseFooter from './BaseFooter.vue'
import sideBarLeft from './sideBarLeft.vue';
export default {
  components: { 
    sideBarLeft,
    BaseHeader,
    BaseMain,
    // BaseFooter
 },
    data() {
            return{
                // nav_bar_show:true
                n_md:900,
                shouldDisplayIcon: false
            }
    }, 
    created() {
    window.addEventListener('resize', this.updateShouldDisplayIcon);
    this.updateShouldDisplayIcon();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateShouldDisplayIcon);
    },
    methods:{
        ShowNavBar(){
            this.$store.commit('ShowNav');
        },
        updateShouldDisplayIcon() {
          this.shouldDisplayIcon = window.innerWidth >= this.n_md;
        },
    },
    computed:{
        navBarStatus(){
        return store.state.nav_bar_status
        }
    },
        

}
</script>

<style lang="scss" scoped>
.main_collapse{
    width: 100%;
}
.main_expanded_sm{
    width: calc(100% - 50px);
    margin-left: 50px;
}

.main_expanded_md{
    width: calc(100% - 200px);
    margin-left: 200px;
}
.width_important{
    // width: 13%;
    z-index: 10;
}
.font_awesome_style{
    font-size: 20px;
}
.fixed_header{
    position: fixed;
    z-index: 10;
    display: block;
    background-color: $hrcoffee-text-primary;
    box-shadow: 10px -1px 15px 5px $hrcoffe-shadow-grey;
    animation: downEnter ease 0.5s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
}

.fixed_header_expanded{
    width: calc(100% - 50px);
    left: 50px;
}

.fixed_header_expanded_md{
    width: calc(100% - 200px);
    left: 200px;
}

.fixed_header_collapsed{
    width: 100%;
}
/*----------------------
        ANIMATION
-----------------------*/
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

</style>
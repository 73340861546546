<template>
     <section class="container-fluid vh-100 d-flex justify-content-center align-items-center">
    <div class="abbonamenti_box d-flex  ">
        <div class=" col-4 abbonamento_single_left d-flex flex-column justify-content-center align-items-center">
            <div class=" col my-2 d-flex flex-column align-items-start justify-content-center text-start">
                <h5 class="mx-3">
                    Ci dispiace il tuo abbonamento è scaduto !
                </h5>
                <div class="mx-3">
                    Se vuoi mantenere Attivo il tuo profilo aziendale, ma vuoi cancellare i tuoi file e i dati dei tuoi dipendenti non Admin clicca
                    <span class="pointer special_char" @click="DeletefileAndNoAdminForm">qui</span>
                </div>
                <div class="mx-3 my-4">
                    Oppure scegli subito il tuo numero di dipendenti e rinnova il tuo abbonamento 
                </div>
             
            </div>
            <div class=" description_box col my-2">
                <label for="">Scegli il numero di dipendenti</label>
                <select class="select_style" name="" id="" v-model="Abbonamento_selected">
                    <option v-for="(price,index) in listino_prezzi" :key="index" :value="price">{{price.numero_dipendenti}}</option>
                </select>
            </div>
        </div>
        <div class=" col-4  abbonamento_single_right d-flex flex-column justify-content-center align-items-center gap-3">
            <!-- <div class="col border_debug my-3 description_box">
                Descrizione Abbonamento :
                <br>
                {{ Abbonamento_selected }} 
            </div> -->
            <div v-if="Abbonamento_selected != null " class="col  my-3 description_box">
                Descrizione Abbonamento :
                <div>
                    Numero dipendenti : {{ Abbonamento_selected.numero_dipendenti }} 
                </div>
                <div>
                   prezzo mensile : {{ Abbonamento_selected.prezzo_mensile }} 
                </div>
                <div>
                   numero mesi abbonamento : {{ Abbonamento_selected.numero_mesi }} 
                </div>
                
            </div>
            <div v-if="Abbonamento_selected != null " class="col-3  d-flex justify-content-center description_box">
                <input type="text" class="select_style" placeholder="Inserisci codice sconto">
            </div>
            <div v-if="Abbonamento_selected != null " class="col-2  my-3">
                <button @click="changeCard">Invia</button>
            </div>
            
        </div>
    </div>
   
    
  </section>
</template>

<script>
import axios from 'axios'
import store from '@/store';

export default {
    name:'AbbonamentoScadutoComponent',
    data() {
        return {
            listino_prezzi:[],
            Abbonamento_selected: null,
            card_subscriptions:true
        }
    },
    mounted() {
        this.getPriceList()
    },
    computed:{
        CompanyId() {
         return store.state.user_company_id;
        }
    },
    methods: {
        changeCard(){

        },
        getPriceList(){
            axios.get('/api/listino_prezzi/').then( response => {
                // console.log(response.data)
                this.listino_prezzi = response.data
                this.Abbonamento_selected = response.data[0]
            })
        },
        DeletefileAndNoAdminForm(){
            // console.log("sei nella delete")
                this.$swal.fire({
                title: 'Eliminazione file e utenti ',
                text: 'Sei sicuro di voler eliminare i file e gli utenti non Admin in maniera definitiva ? ' , 
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sì, elimina',
                cancelButtonText: 'Annulla'
                })
                .then(result => {
                    // console.log(result)
                if (result.isConfirmed) {
                // console.log("SI" )
                this.deleteFileAndNoAdmin()
                
                }
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    // console.log(error.response)
                // Gestisci gli errori o le eccezioni
                });
        },

        deleteFileAndNoAdmin(){
            // console.log("sonon dentro " , this.CompanyId)
            const formData = new FormData()
            formData.append('azienda_id', this.CompanyId); 
            let url = "/analitics/Delete_Data_Users_File/" 
          // ELIMINAZIONE GESTITA DAL BACKEND 
          // eslint-disable-next-line no-unused-vars
            axios.post(url, formData).then(response =>{
                // console.log(response.data)
                this.close();
                this.$swal('Tutti i file e Gli utenti non amministratori sono stati eliminati!', '', 'success');
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.description_box{
    width: 80%;
}
.select_style{
    width: 80%;
    background-color: transparent;
    border: 1px solid lightgray;
    padding: 5px 30px;
    border-radius: 10px;
}

.border_debug{
    border: 1px solid black;
}

.abbonamenti_box{
    width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 1px 2px 15px 9px rgba(182, 179, 179, 0.733);
    gap: 20%;
    background-image: url('@/assets/img/AskWithSplash.png');
    background-attachment: fixed;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

}
.special_char{
    color: blue;
    font-weight: bold;
    transition: 0.2s;
}
.special_char:hover{
    color: rgb(0, 110, 255);
    font-weight: bold;
}
.abbonamenti_box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.6; /* Imposta l'opacità del velo bianco */
}

.abbonamento_single_left,.abbonamento_single_right{
    position: relative;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.897);
    height: 80vh;
    margin: 0px 15px;
    border-radius: 15px;
    box-shadow: 1px 2px 15px 9px rgba(182, 179, 179, 0.349);
}

.abbonamento_single_left{
    animation: leftEnter ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

.abbonamento_single_right{
    animation: rightEnter ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}


</style>
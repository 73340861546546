<template>
  <main class="main_structure ">
    <div class="routing  py-5" v-if="$store.state.can_operate">
      
        <router-view></router-view>
    </div>
    <div class="routing opacity_retard py-5" v-else>
      <h1>Abbonamento Scaduto</h1>
      <router-link to="/subscription-expired-page">
        <button class="button_abbonamento">Rinnova abbonamento</button>
      </router-link>
    </div>
    <div>
        <base-footer/>
    </div>
    
  </main>
  
</template>

<script>
import BaseFooter from './BaseFooter.vue'
import store from '../../store';

export default {
  components: { 
    BaseFooter 
  },
    name:'BaseMain',
    computed:{
      CanOperate(){
      return store.state.validate_data_file.can_operate
    },
    }
}
</script>

<style lang="scss" scoped>

.main_structure{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .routing{
      margin-top: 35px;
        flex-grow: 1;
        background-size: 350px;
        background-position: top right;
        background-repeat: no-repeat;
        background-attachment: top;
        background-image: url(./../../assets/img/splashbackground.png);
        animation: fadeInAnimation ease 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
    }

    .opacity_retard{
      animation: fadeInAnimation ease 3s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
    }

}

</style>
import axios from 'axios';

// LOCALE 
// export const WS_URL = '127.0.0.1:8000';
// axios.defaults.baseURL = "http://127.0.0.1:8000/"


// PRODUZIONE
export const WS_URL = 'devbackend.hrcoffee.eu';
axios.defaults.baseURL = "https://devbackend.hrcoffee.eu/"



export default {
  "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome page"])},
  "WelcomeSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to continue"])},
  "HomePageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! Download or upload your models and start analyzing your data with a simple Splash !"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciao i18n !!"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download template"])},
  "downloadobj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "Italiano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "Inglese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Spagnolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "Learning Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Analytics"])},
  "Learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning"])},
  "Competenze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "Revisione salariale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Review"])},
  "HelpDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HelpDesk"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Account"])},
  "Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "ForgotPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password?"])},
  "EmptyUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Empty user field"])},
  "EmptyPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Empty password field"])},
  "Error400UserPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Incorrect username or password"])},
  "loadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the file using the button or drag it here"])},
  "SelectFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or select a folder"])},
  "loadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload done successfully !"])},
  "loadStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record upload start"])},
  "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "errorLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading"])},
  "Avanti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "JobFamilySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Job Family"])},
  "user40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present users of 40+ years"])},
  "user35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present users of 35+ years"])},
  "user30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present users of 30+ years"])},
  "user20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present users of 20+ years"])},
  "user5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present users of less than 5 years"])},
  "rangeHiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select range - date of intake"])},
  "SelectEmploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an employee"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "HrAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrAnalytics"])},
  "HrReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrReporting"])},
  "Archivio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "EditCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit company profile"])},
  "NewCompanyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here and enter your data immediately"])},
  "NewCompanyProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here and enter your company profile immediately"])},
  "ManageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])},
  "NewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new user"])},
  "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "GeneratePw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Password"])},
  "GptActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate ChatGpt"])},
  "GptDeactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate ChatGpt"])},
  "AllowGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*By clicking on the checkbox you agree to the use of ChatGPT "])},
  "BackToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the login page"])},
  "ForgotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to recover your password"])},
  "privacyGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization to use the ChatGPT system"])},
  "privacyGptP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash has a great ally for you! We have chosen to include the innovative ChatGPT system to help you elaborate on the comments related to your graphs! For these reasons, take the time to read the following terms carefully before proceeding."])},
  "privacyGptP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know that data collection, chart processing, description, and reporting require valuable time and energy, which is usually taken away from your work. With Splash we want to give you the key capabilities of People Analytics to help you interpret data and make decisions about managing your personnel in the shortest possible time, giving an advantage to both you and your employees."])},
  "privacyGptList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All data must be kept and processed with care, so we care about privacy and we want to give you all the useful information about it:"])},
  "privacyGptLi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will need to create an account to use your data"])},
  "privacyGptLi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are the holder of your data, therefore the responsibility of what data to provide and how to use them is under your responsibility. We will only do what is necessary for our services to function properly."])},
  "privacyGptLi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may make changes to our terms, services, and/or fees from time to time. "])},
  "privacyGptLi4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to remind you that by using our service you assume responsibility for any consequences attributable to the use of ChatGPT  "])},
  "privacyGptLi5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For legal details and specific information you can always refer to the Splash Privacy by Design page.  "])},
  "privacyGptWordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design di Splash."])},
  "Introduzione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
  "IntroduzioneP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document describes the Privacy by Design measures adopted while designing Splash, a people analytics system, in order to ensure the protection of users' personal data when using ChatGPT APIs. Splash was built with the commitment to respect user privacy and comply with data protection regulations, with admin user security and control at its core."])},
  "IntroduzioneP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a people analytics system, Splash enables companies to obtain a detailed and actionable analysis of personnel information, providing a complete and in-depth overview of business dynamics. In this context, the use of ChatGPT APIs represents an added value for companies, allowing comments and reports to be generated quickly and effectively."])},
  "IntroduzioneP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being aware of the importance of privacy and the need to ensure that personal data is processed in accordance with current regulations, Splash developers have adopted Privacy by Design measures, which integrate privacy best practices into the system design and implementation process."])},
  "IntroduzioneP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following sections of the document will discuss the measures taken by the Splash team to ensure the privacy of personal data in general, and in relation to specific areas such as IT systems, good business practices and structural design, and network infrastructure. The seven main areas of Privacy by Design will also be explored, which include the proactive approach, privacy as the default setting, the integration of privacy into the design, maximum functionality, full security, visibility and transparency, and respect for user privacy."])},
  "IntroduzioneP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through the implementation of these measures, Splash is committed to providing a secure, functional, and privacy-driven people analytics platform, where the admin user has control over the processing of personal data and can make well-informed decisions regarding the time limits of data storage and the use of ChatGPT."])},
  "PrivacyByDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design in Splash"])},
  "PrivacyByDesignP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash is a people analytics platform designed to enable companies to gain meaningful insights into personnel dynamics. "])},
  "PrivacyByDesignP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Through the use of advanced analytical technologies, Splash collects and analyzes employee data, enabling companies to make informed decisions and improve organizational performance.  "])},
  "PrivacyByDesignP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash development team understands the importance of privacy and anonymization of personal data.  "])},
  "PrivacyByDesignP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In an increasingly connected world, it is critical to ensure that sensitive employee information is treated securely and that appropriate measures are taken to protect each individual's privacy. "])},
  "PrivacyByDesignP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" To this end, Splash is based on the Privacy by Design approach, a concept that integrates privacy from the very beginning of the system design process. "])},
  "PrivacyByDesignP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" This means that privacy is considered a central element from the initial stages of Splash's development, rather than being a later or responsive addition. "])},
  "PrivacyByDesignP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" This approach ensures that privacy is embedded in the system from its conception, protecting users' personal data proactively. "])},
  "PrivacyByDesignP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash implements advanced security measures to protect users' personal data.   "])},
  "PrivacyByDesignP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cryptographic technologies and protocols are adopted to ensure the security of data in transit and at rest.  "])},
  "PrivacyByDesignP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" In addition, access and authentication controls are implemented to restrict access to data only to authorized users.The IT systems used by Splash are constantly monitored and updated to maintain a high level of security. "])},
  "PrivacyByDesignList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The following are the seven main areas of Privacy by Design: "])},
  "privacyTitleList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair Business Practices"])},
  "privacyTitleList1P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash is committed to conducting its business activities in compliance with data protection regulations. Splash's business policies and practices are designed to ensure the confidentiality and security of users' personal data. Measures are taken to prevent unauthorized disclosure or misuse of data and to ensure that personal information is processed in a lawful and transparent manner."])},
  "privacyTitleList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structural Design and Network Infrastructures"])},
  "privacyTitleList2P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integrates privacy into structural design and network infrastructure. Technical and organizational measures are taken to ensure that personal data is protected from unauthorized access or misuse. Firewalls, log monitoring, and security controls are implemented to prevent security breaches and ensure data confidentiality and integrity."])},
  "privacyTitleList3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proactive, Non-Reactive – Prevention, Not Correction"])},
  "privacyTitleList3P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash takes a proactive approach to data privacy, trying to prevent potential breaches or issues instead of dealing with them reactively. Security measures and data management policies are implemented to identify and mitigate privacy risks early in the development and implementation of Splash's people analytics system."])},
  "privacyTitleList4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Default"])},
  "privacyTitleList4P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash configures your people analytics system so that privacy is set as the default. Technical and organizational measures are taken to ensure that personal data is protected from the outset and that the user must provide explicit consent for any data processing operations that exceed the intended purposes."])},
  "privacyTitleList5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Built into the Design"])},
  "privacyTitleList5P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integrates privacy into the design of the people analytics system. Measures shall be taken to minimize the use of personal and identifying data, so as to exclude its processing whenever possible. Pseudonymization and anonymization mechanisms are implemented to protect the identity of users while ensuring the validity of the analyses and reports generated."])},
  "privacyTitleList6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Functionality - Positive Value, Not Zero Value"])},
  "privacyTitleList6P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash is committed to delivering a functional and valuable experience for users, without compromising privacy. Features are implemented that allow users to obtain detailed and useful analyses without compromising the confidentiality of personal data. Measures are taken to ensure that data processing takes place in compliance with privacy regulations and with the utmost accuracy and relevance."])},
  "privacyTitleList7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety from Beginning to the End - Full Lifecycle Protection"])},
  "privacyTitleList7P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash ensures the security of personal data throughout the life cycle of the people analytics system. Security measures are implemented to protect data during collection, storage, processing, and transmission. Secure deletion protocols are adopted to ensure the permanent deletion of personal data when it is no longer needed or required."])},
  "privacyTitleList8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility and Transparency - Maintaining Transparency"])},
  "privacyTitleList8P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash is committed to maintaining transparency regarding its privacy policies and practices. Clear and understandable information is provided to users regarding the handling of their personal data, including the types of data collected, the purposes of the processing, the rights of users, and the security measures taken. In addition, Splash is committed to providing control mechanisms and consent to the admin user, so that he can make informed decisions regarding the processing of personal data."])},
  "privacyTitleList9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect for User Privacy - User Centricity"])},
  "privacyTitleList9P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash focuses on respect for user privacy. Measures are taken to ensure that personal data is processed lawfully, fairly, and transparently, respecting users' preferences and rights. Mechanisms are provided for the exercise of users' rights, such as the right of access, rectification, and deletion of personal data."])},
  "Conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
  "ConclusionP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash implements Privacy by Design measures in the people analytics system to ensure the protection of users' personal data when using ChatGPT APIs"])},
  "ConclusionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through taking proactive approaches, incorporating privacy into the design, employing fair business policies and practices, and focusing on security, Splash is committed to providing a functional, secure, and privacy-friendly platform."])},
  "ConclusionP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash is committed to providing the admin user with the necessary tools to manage and control data storage and usage of ChatGPT. "])},
  "ConclusionP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Through an intuitive interface, the admin user can easily set data storage time limits and enable or disable the use of ChatGPT.  "])},
  "ConclusionP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This gives the admin user full control over the processing of personal data and allows Splash's system to be adapted to business needs and internal data retention policies."])},
  "ConclusionP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In compliance with the principles of Privacy by Design, Splash is committed to ensuring that the collection, processing, and storage of personal data takes place in a secure manner and in compliance with current regulations. "])},
  "ConclusionP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Data anonymization is a key component of Splash's privacy strategy.  "])},
  "ConclusionP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymization techniques are applied to protect the identity of users, replacing identifying data with unique identifiers or aggregating data so that users cannot be individually identified.  "])},
  "ConclusionP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ensures that the analytics and reports generated by Splash are based on anonymized data, minimizing the risk of disclosure of sensitive personal information."])},
  "ConclusionP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash is committed to constantly monitoring the effectiveness of the Privacy by Design measures implemented, in order to identify and address any vulnerabilities or privacy risks.  "])},
  "ConclusionP11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic assessments of the security of data management systems and procedures are conducted to ensure adequate protection of users' personal information."])},
  "ConclusionP12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash, as a people analytics system, adopts Privacy by Design measures to guarantee the protection of users personal data when using the ChatGPT API.The admin user has control over data storage time limits and the use of ChatGPT, allowing flexible and personalized management of data and interactions with the system.  "])},
  "ConclusionP13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash guarantees transparency, security and respect for the privacy of all the users including the admin user, ensuring that decisions regarding the management of personal data are taken in a well-informed manner."])},
  "commentAllChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment all charts with ChatGPT"])},
  "AllChartAreCommented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All charts are already commented"])},
  "AnonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous Data"])},
  "DeanonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible Data"])},
  "GptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ChatGPT Suggestion"])},
  "WriteReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your report"])},
  "loadMessageSpalsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait a moment for your splash"])},
  "maxChartSingleReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum 5000 characters. Characters remaining:"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "ElaborateGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph processing in progress"])},
  "ErrorUpdateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry! There was an error in updating the report"])},
  "ErrorReportGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry! There was an error in generating the report"])},
  "GenerateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Report"])},
  "GenerateReportMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To display the report, generate all single reports"])},
  "FileDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File successfully deleted!"])},
  "optionGraphTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph settings"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You: "])},
  "Gpt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max: "])},
  "clean_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear chat"])},
  "hi_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I'm your AI,"])},
  "ask_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask me anything you want! "])},
  "content_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can help you generate images, text, presentations, and much more in any field of your interest"])},
  "try_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put me to the test! "])},
  "insert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert text"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "GraphOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph Settings"])},
  "Legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
  "ChangeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Color"])},
  "ColorQuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to proceed with changing the graph color?"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes made"])},
  "colorChangeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The graph color has been updated successfully."])},
  "colorChangeNoSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry, but the graph color change was not successful."])},
  "organization_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Unit"])},
  "info_unitOrg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This screen provides a detailed overview of charts related to the distributions of each variable, divided by different organizational units."])},
  "info_unitOrg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, you can access comprehensive information that visually illustrates the distribution of data, allowing for an in-depth understanding of the characteristics of each variable within each unit."])},
  "info_unitOrg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to this feature, you will have a clear and detailed view of organizational dynamics, facilitating the decision-making process and the monitoring of your company."])},
  "elaboration_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph processing in progress"])},
  "intro_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboration introduction report"])},
  "correlations_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboration correlation data"])},
  "conclusion_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboration conclusion"])},
  "New_question_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New ChatGPT suggestion"])},
  "info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey there!<br><br> Just a quick heads-up:<br><br> Before diving into creating the overall report, make sure you've generated reports for each individual chart.<br><br> It's like putting together a puzzle - each piece contributes to the complete picture!<br> By generating specific reports, we gather detailed data from every angle, making our overall report more accurate and informative.<br><br> So, take a moment to handle them before moving forward."])},
  "delete_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the PDF file "])},
  "info_no_change_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The PDF has not been deleted "])},
  "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon!"])},
  "info_load_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the loading of files and graphs, it is recommended not to navigate between pages to ensure a smooth and complete process."])},
  "salary_archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Review Archive"])},
  "elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report in process"])},
  "info_elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(It is recommended to wait for the completion of the process before changing the page)"])},
  "info_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your report with a simple click on the PDF button!<br><br> Once generated, you will find your documents in the HR Reporting area.<br><br> If you are not satisfied with the generated report, you can press the 'ChatGPT Suggestion' button again to generate a new one."])},
  "Revisione_salariale_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary_Review_Report"])},
  "elaborating_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are creating graphic magic on Splash!"])},
  "waiting_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It will only take a moment!"])},
  "noPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No PDF present."])},
  "noPdf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To dive into the world of HR reporting, navigate to the desired area, click on 'Report,' and generate your PDF."])},
  "option_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected settings"])},
  "expiration_pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password expiration:"])},
  "expiration_archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives expiration:"])},
  "expiration_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File expiration:"])},
  "status_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ChatGpt status:"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "disattivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "employee_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees:"])},
  "select_uo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Organizational Unit"])},
  "totale_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Distributions:"])},
  "name_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Name"])},
  "type_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Type"])},
  "name_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
  "data_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "gpt_module_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ChatGpt Module Activation"])},
  "help_desk_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave your information, and we will contact you as soon as possible"])},
  "name_and_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name and Surname *"])},
  "email_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Company Email *"])},
  "request_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about your request *"])},
  "privacy_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to the processing of my data and declare that I have read the Privacy Policy."])},
  "anonymation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be informed that when you switch from anonymous to visible status, the 'Chatgpt Suggestion' buttons will be disabled to ensure your privacy."])},
  "updateGraphApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update data"])},
  "lastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
  "connnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
  "performanceArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Archive"])},
  "performanceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Report"])},
  "skillsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills Report"])},
  "totalRegression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Regression"])},
  "totalPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Performance"])},
  "regressionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This screen provides a detailed overview of the graphs related to correlations and regressions between variables.<br><br> Here you will find a series of graphs and tools dedicated to analyzing the relationships between variables within the business context. Regressions are a powerful tool for understanding how different variables influence each other, identifying the general trend, and evaluating the strength and direction of the correlation. Thanks to this feature, you can explore the relationships between variables to better understand data trends.<br><br> Evaluate the impact of variables on the desired outcome.<br><br> Use the results to make informed decisions and optimize business strategies."])},
  "performanceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This visualization provides a detailed overview of charts related to company performance.<br><br>Here you will find a series of charts and tools dedicated to analyzing the relationships between variables in the context of business performance.<br><br>These tools are essential for understanding how different variables influence overall performance, allowing you to identify general trends.<br><br>Through this feature, you can explore the status of variables to gain a better understanding of performance dynamics and evaluate the impact of each variable on achieving desired results.<br><br>The results obtained can be used to make informed decisions and optimize business strategies in terms of performance."])},
  "potentialInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This representation provides a detailed overview of charts related to performance potential. Here you will have access to a series of charts and tools dedicated to analyzing the relationships between variables in the context of potential compared to performance.<br><br>These tools are essential for understanding how different variables influence the overall potential of the company, allowing you to identify general trends.<br><br>Through this functionality, you can explore the status of variables to gain a deeper understanding of business potential dynamics and evaluate the impact of each variable on achieving desired results.<br><br>The results obtained can be used to make informed decisions and optimize business strategies to maximize potential."])},
  "salaryReviewInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This visualization provides a detailed overview of charts related to salary review.<br><br>Here you will have access to a series of charts and tools dedicated to analyzing the relationships between variables contributing to salary review.<br><br>These tools are essential for understanding how different variables influence the company's salary review, allowing you to identify general trends.<br><br>Through this functionality, you can explore the status of variables to gain a deeper understanding of salary review dynamics and evaluate the impact of each variable on achieving desired results.<br><br>The results obtained can be used to make informed decisions and optimize business strategies to maximize salary review."])},
  "infoJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This screen provides a detailed view through charts on the average skill ratings, broken down by Job Title.<br><br>Here you will find clear and visual information on the status of skills, allowing you to fully understand the ratings associated with each Job Title.<br><br>Thanks to this feature, you will have access to a comprehensive and detailed overview of ratings, simplifying the decision-making process and human capital monitoring."])}
}
export default {
  "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina de bienvenida"])},
  "WelcomeSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder para continuar"])},
  "HomePageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bienvenido! ¡Descargue o cargue sus modelos e inicie el análisis de datos con un simple Splash!"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola i18n !!"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar modelo"])},
  "downloadobj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "Italiano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "Inglese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "Spagnolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "Learning Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Analytics"])},
  "Learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning"])},
  "Competenze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencias"])},
  "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "Revisione salariale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión salarial"])},
  "HelpDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HelpDesk"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Account"])},
  "Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accede"])},
  "ForgotPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has olvidado la contraseña?"])},
  "EmptyUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Campo de usuario en blanco"])},
  "EmptyPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Campo de contraseña en blanco"])},
  "Error400UserPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nombre de usuario o contraseña incorrectos"])},
  "loadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga el archivo utilizando el botón o arrástralo aquí"])},
  "SelectFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o seleccione una carpeta"])},
  "loadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga realizada con éxito !"])},
  "loadStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de carga récord"])},
  "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En elaboración"])},
  "errorLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al cargar"])},
  "Avanti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelante"])},
  "JobFamilySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Job Family"])},
  "user40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios presentes durante más de 40 años"])},
  "user35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios presentes durante más de 35 años"])},
  "user30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios presentes durante más de 30 años"])},
  "user20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios presentes durante más de 20 años"])},
  "user5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios presentes desde hace menos de 5 años"])},
  "rangeHiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar rango - fecha de antiguedad"])},
  "SelectEmploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar empleado"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account "])},
  "HrAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrAnalytics "])},
  "HrReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HrReporting "])},
  "Archivio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "EditCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil de la empresa"])},
  "NewCompanyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic aquí e ingresa tus datos de inmediato"])},
  "NewCompanyProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic aquí e ingresa tus datos de inmediato"])},
  "ManageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar usuarios"])},
  "NewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo usuario"])},
  "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
  "GeneratePw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar contraseña"])},
  "GptActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar ChatGpt"])},
  "GptDeactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar Gpt"])},
  "AllowGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Al hacer clic en la casilla de verificación, consientes el uso de ChatGPT"])},
  "BackToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la página de inicio de sesión"])},
  "ForgotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu email para recuperar tu contraseña"])},
  "privacyGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización para utilizar el sistema de chat GPT"])},
  "privacyGptP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash tiene un gran aliado para ti. ¡Hemos elegido incorporar el innovador sistema de chat GPT para ayudarte a elaborar descripciones comentadas de tus gráficos! Por esta razón, tómate el tiempo necesario para leer cuidadosamente los siguientes términos antes de proceder."])},
  "privacyGptP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sabemos que la recopilación de datos, el procesamiento de gráficos, su descripción y los informes requieren tiempo y energía valiosos que normalmente se restan de tu trabajo. Con Splash, queremos ofrecerte las funciones clave de People Analytics para ayudarte a interpretar los datos y tomar decisiones sobre la gestión de tus personas en el menor tiempo posible, ofreciendo una ventaja tanto para ti como para tus colaboradores."])},
  "privacyGptList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada dato debe ser custodiado y procesado con cuidado, por lo que nos preocupamos por la privacidad y queremos brindarte toda la información útil al respecto:"])},
  "privacyGptLi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberás crear una cuenta para utilizar nuestros datos."])},
  "privacyGptLi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eres el titular de tus datos, por lo tanto, la responsabilidad de los mismos está vinculada al uso que decidas hacer bajo tu responsabilidad. Nosotros solo haremos lo necesario para que nuestros servicios funcionen correctamente. "])},
  "privacyGptLi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vez en cuando, podemos realizar cambios en nuestros términos, servicios y/o tarifas. Si tienes una cuenta y no te gusta una nueva modificación o actualización de nuestra plataforma, siempre podrás cancelarla en cualquier momento."])},
  "privacyGptLi4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queremos recordarte que al utilizar nuestro servicio asumes la responsabilidad de eventuales consecuencias imputables al uso normal de Chat GPT.  "])},
  "privacyGptLi5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para detalles legales e información específica, siempre puedes leer la página de Privacy by Design de Splash.  "])},
  "privacyGptWordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design di Splash."])},
  "Introduzione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción"])},
  "IntroduzioneP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este documento describe las medidas de Privacy by Design adoptadas por Splash, un sistema de people analytics, para garantizar la protección de los datos personales de los usuarios en el uso de las API de ChatGPT. Splash se compromete a respetar la privacidad de los usuarios y a cumplir con las normativas de protección de datos, centrándose en la seguridad y el control del usuario admin."])},
  "IntroduzioneP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como un sistema de people analytics, Splash permite a las empresas obtener análisis detallados y útiles sobre la información del personal, proporcionando una visión completa y profunda de la dinámica empresarial. En este contexto, el uso de las API de ChatGPT representa un valor añadido para las empresas, lo que permite generar comentarios e informes de forma rápida y eficaz."])},
  "IntroduzioneP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin embargo, consciente de la importancia de la privacidad y de la necesidad de garantizar un tratamiento de los datos personales conforme a las normativas vigentes, Splash ha adoptado medidas de Privacy by Design, integrando las mejores prácticas de privacidad en el proceso de diseño e implementación del sistema."])},
  "IntroduzioneP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A continuación, explicaremos las medidas adoptadas por Splash para garantizar la privacidad de los datos personales, en general y en relación con áreas específicas como los sistemas de IT, las prácticas comerciales correctas y el diseño estructural y la infraestructura de red. También se profundizarán las siete áreas principales de Privacy by Design, que incluyen el enfoque proactivo, la privacidad como configuración predeterminada, la integración de la privacidad en el diseño, la máxima funcionalidad, la seguridad hasta el final, la visibilidad y transparencia y el respeto de la privacidad del usuario."])},
  "IntroduzioneP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A través de la implementación de estas medidas, Splash se compromete a proporcionar una plataforma de people analytics segura, funcional y respetuosa con la privacidad, donde el admin tiene control sobre el procesamiento de datos personales y puede tomar decisiones informadas y con conocimiento sobre los límites de tiempo de almacenamiento de datos y el uso de ChatGPT."])},
  "PrivacyByDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy by Design en Splash"])},
  "PrivacyByDesignP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash es una plataforma de people analytics diseñada para ayudar a las empresas a obtener información detallada y significativas sobre la dinámica del personal. A través del uso de tecnologías analíticas avanzadas, Splash recopila y analiza datos relacionados con los empleados, lo que permite a las empresas tomar decisiones informadas y mejorar el rendimiento organizacional.  "])},
  "PrivacyByDesignP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin embargo, Splash entiende la importancia de la privacidad y la anonimización de los datos personales.  "])},
  "PrivacyByDesignP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En un mundo cada vez más conectado, es vital garantizar que la información confidencial de los empleados se trate de forma segura y que se tomen las medidas adecuadas para proteger la privacidad de cada individuo.   "])},
  "PrivacyByDesignP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con este fin, Splash adopta un enfoque basado en Privacy by Design, un concepto que integra la privacidad desde el inicio del proceso de diseño del sistema. "])},
  "PrivacyByDesignP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Esto significa que la privacidad se considera un elemento central desde las etapas iniciales de desarrollo de Splash, en lugar de ser una adición posterior o receptiva.  "])},
  "PrivacyByDesignP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Este enfoque garantiza que la privacidad se incorpore al sistema desde su concepción, protegiendo los datos personales de los usuarios de manera proactiva."])},
  "PrivacyByDesignP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Splash implementa medidas de seguridad avanzadas para proteger los datos personales de los usuarios.  "])},
  "PrivacyByDesignP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Se adoptarán tecnologías y protocolos criptográficos para garantizar la seguridad de los datos en tránsito y en reposo.  "])},
  "PrivacyByDesignP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Además, se implementan controles de acceso y autenticación para limitar el acceso a los datos solo a los usuarios autorizados.   "])},
  "PrivacyByDesignP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los sistemas de IT que utiliza Splash se supervisan y actualizan constantemente para mantener un alto nivel de seguridad. "])},
  "PrivacyByDesignList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A continuación se describen las siete áreas principales de Privacy by Design: "])},
  "privacyTitleList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prácticas comerciales correcta"])},
  "privacyTitleList1P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash se compromete a llevar a cabo sus actividades comerciales de acuerdo con las regulaciones de protección de datos. Las políticas y prácticas comerciales de Splash están diseñadas para garantizar la confidencialidad y seguridad de los datos personales de los usuarios. Se toman medidas para evitar la divulgación no autorizada o el uso indebido de datos y para garantizar que la información personal se trate de manera lícita y transparente."])},
  "privacyTitleList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño Estructural e Infraestructura de Red"])},
  "privacyTitleList2P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integra la privacidad en el diseño estructural y la infraestructura de red. Se toman medidas técnicas y organizativas para garantizar que los datos personales estén protegidos contra el acceso no autorizado o el uso indebido. Se implementan firewall, monitoreo de registros y controles de seguridad para evitar violaciones de seguridad y garantizar la confidencialidad e integridad de los datos."])},
  "privacyTitleList3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proactivo no reactivo - prevenir no corregir"])},
  "privacyTitleList3P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash adopta un enfoque proactivo para la privacidad de los datos, tratando de evitar posibles infracciones o problemas en lugar de abordarlos de manera reactiva. Se implementan medidas de seguridad y políticas de gestión de datos para identificar y mitigar los riesgos de privacidad desde las primeras etapas de desarrollo e implementación del sistema de people analytics de Splash."])},
  "privacyTitleList4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad como configuración por defecto"])},
  "privacyTitleList4P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash configura el sistema de people analytics para que la privacidad se establezca como valor predeterminado. Se toman medidas técnicas y organizativas para garantizar que los datos personales estén protegidos desde el principio y que el usuario tenga que dar su consentimiento explícito para cualquier operación de procesamiento de datos que exceda los fines previstos."])},
  "privacyTitleList5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad incorporada en el diseño"])},
  "privacyTitleList5P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash integra privacidad en el diseño del sistema de people analytics. Se toman medidas para minimizar el uso de datos personales e identificadores, de modo que se excluye su procesamiento siempre que sea posible. Se implementan mecanismos de seudonimización y anonimización de datos para proteger la identidad de los usuarios, al tiempo que se garantiza la validez de los análisis e informes generados."])},
  "privacyTitleList6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máxima funcionalidad - Valor positivo, no valor cero"])},
  "privacyTitleList6P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash se compromete a ofrecer una experiencia funcional y valiosa para los usuarios sin comprometer la privacidad. Se implementan funciones que permiten a los usuarios obtener análisis detallados y útiles sin comprometer la confidencialidad de los datos personales. Se toman medidas para garantizar que el procesamiento de datos se realiza de acuerdo con las regulaciones de privacidad y con la mayor precisión y pertinencia."])},
  "privacyTitleList7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad hasta el final - Protección completa del ciclo de vida"])},
  "privacyTitleList7P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash garantiza la seguridad de los datos personales durante todo el ciclo de vida del sistema de people analytics. Se implementan medidas de seguridad para proteger los datos durante la recopilación, el almacenamiento, el procesamiento y la transmisión. Se adoptan protocolos de eliminación segura para garantizar la eliminación definitiva de los datos personales cuando ya no son necesarios o solicitados."])},
  "privacyTitleList8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad y transparencia - Mantener la transparencia"])},
  "privacyTitleList8P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash se compromete a mantener la transparencia de sus políticas y prácticas de privacidad. Se proporciona a los usuarios información clara y comprensible sobre el tratamiento de sus datos personales, incluidos los tipos de datos recopilados, los fines del tratamiento, los derechos de los usuarios y las medidas de seguridad adoptadas. Además, Splash se compromete a proporcionar mecanismos de control y consentimiento al usuario admin, para que pueda tomar decisiones informadas sobre el procesamiento de datos personales."])},
  "privacyTitleList9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respeto por la privacidad del usuario - Centralidad del usuario"])},
  "privacyTitleList9P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash se centra en el respeto por la privacidad del usuario. Se toman medidas para garantizar que los datos personales se traten de manera legal, justa y transparente, respetando las preferencias y los derechos de los usuarios. Se proporcionan mecanismos para el ejercicio de los derechos de los usuarios, como el derecho de acceso, rectificación y eliminación de datos personales."])},
  "Conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusiones"])},
  "ConclusionP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash implementa medidas de Privacy by Design en el sistema de people analytics para garantizar la protección de los datos personales de los usuarios en el uso de las API de ChatGPT. "])},
  "ConclusionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A través de la adopción de enfoques proactivos, la incorporación de la privacidad en el diseño, el uso de políticas y prácticas comerciales correctas, y el enfoque en la seguridad, Splash se compromete a proporcionar una plataforma funcional, segura y respetuosa con la privacidad."])},
  "ConclusionP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash se compromete a proporcionarle las herramientas necesarias para administrar y controlar el almacenamiento de datos y el uso de ChatGPT."])},
  "ConclusionP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  A través de una interfaz intuitiva, el usuario admin puede establecer fácilmente los límites de tiempo de almacenamiento de datos y activar o desactivar el uso de ChatGPT.  "])},
  "ConclusionP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto le otorga al usuario admin un control total sobre el procesamiento de datos personales y le permite adaptar el sistema de Splash a las necesidades comerciales y las políticas internas de retención de datos."])},
  "ConclusionP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cumplimiento con los principios de Privacy by Design, Splash se compromete a garantizar que la recopilación, el procesamiento y el almacenamiento de los datos personales se lleven a cabo de forma segura y de conformidad con la normativa vigente. "])},
  "ConclusionP7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La anonimización de datos es un componente clave de la estrategia de privacidad de Splash.  "])},
  "ConclusionP8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aplican técnicas de anonimización para proteger la identidad de los usuarios, reemplazando datos de identificación con identificadores únicos o agregando datos para que no se pueda identificar individualmente a los usuarios.  "])},
  "ConclusionP9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto garantiza que los análisis e informes generados por Splash se basan en datos anónimos, lo que minimiza el riesgo de divulgación de información personal sensible."])},
  "ConclusionP10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash supervisa constantemente la efectividad de las medidas de Privacy by Design implementadas para identificar y abordar cualquier vulnerabilidad o riesgo de privacidad.  "])},
  "ConclusionP11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se realizan evaluaciones periódicas de la seguridad de los sistemas y procedimientos de gestión de datos para garantizar una protección adecuada de la información personal de los usuarios."])},
  "ConclusionP12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splash, como un sistema de people analytics, adopta medidas de Privacy by Design para garantizar la protección de sus datos personales en el uso de las API de ChatGPT. El usuario admin tiene control sobre los límites de tiempo de almacenamiento de datos y el uso de ChatGPT, lo que permite una gestión flexible y personalizada de los datos y las interacciones con el sistema. "])},
  "ConclusionP13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalsh se compromete a garantizar la transparencia, la seguridad y el respeto de la privacidad del usuario admin, asegurando que las decisiones sobre la gestión de datos personales se tomen de manera consciente e informada."])},
  "commentAllChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar todos los gráficos con ChatGPT"])},
  "AllChartAreCommented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los gráficos ya están comentados"])},
  "AnonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos Anónimos"])},
  "DeanonymData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos Visibles"])},
  "GptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerencia de ChatGPT"])},
  "WriteReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu informe"])},
  "loadMessageSpalsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera un momento para tu presentación"])},
  "maxChartSingleReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["máximo 5000 caracteres. Caracteres restantes:"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "ElaborateGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando el gráfico"])},
  "ErrorUpdateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Lo sentimos! Hubo un error al actualizar el informe"])},
  "ErrorReportGpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Lo sentimos! Hubo un error al generar el informe"])},
  "GenerateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar Informe"])},
  "GenerateReportMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para mostrar el informe, genera todos los informes individuales"])},
  "FileDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo eliminado con éxito!"])},
  "optionGraphTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del gráfico"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tú: "])},
  "Gpt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max: "])},
  "clean_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar chat"])},
  "hi_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola, soy tu IA,"])},
  "ask_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Pregúntame lo que quieras! "])},
  "content_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo ayudarte a generar imágenes, texto, presentaciones y mucho más en cualquier campo de tu interés"])},
  "try_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ponme a prueba! "])},
  "insert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insertar testo"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "GraphOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de gráficos"])},
  "Legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leyenda"])},
  "ChangeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar color"])},
  "ColorQuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas proceder con la modificación del color del gráfico?"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún cambio realizado"])},
  "colorChangeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El color del gráfico se ha actualizado correctamente."])},
  "colorChangeNoSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentamos informar que la modificación del color del gráfico no tuvo éxito."])},
  "organization_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad organizativa"])},
  "info_unitOrg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pantalla proporciona una descripción detallada de los gráficos relacionados con las distribuciones de cada variable, divididos por las diferentes unidades organizativas."])},
  "info_unitOrg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí puedes acceder a información completa que ilustra visualmente la distribución de datos, permitiéndote comprender a fondo las características de cada variable dentro de cada unidad."])},
  "info_unitOrg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias a esta función, tendrás una visión clara y detallada de las dinámicas organizativas, facilitando el proceso de toma de decisiones y el monitoreo de tu empresa."])},
  "elaboration_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento de gráficos en curso"])},
  "intro_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboración introducción informe"])},
  "correlations_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboración correlación datos"])},
  "conclusion_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboración conclusión"])},
  "New_question_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva sugerencia ChatGPT"])},
  "info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola allí!<br><br> Solo un aviso rápido:<br><br> Antes de sumergirte en la creación del informe general, asegúrate de haber generado informes para cada gráfico individual.<br><br> Es como armar un rompecabezas: ¡cada pieza contribuye a la imagen completa!<br> Al generar informes específicos, obtenemos datos detallados desde cada ángulo, lo que hace que nuestro informe general sea más preciso e informativo.<br><br> Así que, tómate un momento para ocuparte de ellos antes de seguir adelante."])},
  "delete_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que quieres eliminar el archivo PDF "])},
  "info_no_change_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El PDF no ha sido eliminado "])},
  "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Muy pronto!"])},
  "info_load_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la carga de archivos y gráficos, se recomienda no navegar entre las páginas para garantizar un proceso fluido y completo."])},
  "salary_archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo de Revisión Salarial"])},
  "elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe en proceso"])},
  "info_elaboration_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Se recomienda esperar a que se complete el proceso antes de cambiar de página)"])},
  "info_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Crea tu informe con un simple clic en el botón de PDF!<br><br> Una vez generado, encontrarás tus documentos en el área de Informes de Recursos Humanos.<br><br> Si no estás satisfecho con el informe generado, puedes presionar nuevamente el botón 'Sugerencia de ChatGPT' para generar uno nuevo."])},
  "Revisione_salariale_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe_de_revisión_salarial"])},
  "elaborating_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Estamos creando magia gráfica en Splash!"])},
  "waiting_graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Solo tomará un momento!"])},
  "noPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ningún PDF presente."])},
  "noPdf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para sumergirte en el mundo de HR reporting, ve al área deseada, haz clic en 'Report' y genera tu PDF."])},
  "option_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones seleccionadas"])},
  "expiration_pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencimiento de contraseñas:"])},
  "expiration_archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencimiento de archivos:"])},
  "expiration_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencimiento de archivos:"])},
  "status_gpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de ChatGpt:"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "disattivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "employee_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de empleados:"])},
  "select_uo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Unidad Organizativa"])},
  "totale_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de Distribuciones:"])},
  "name_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del PDF"])},
  "type_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Área"])},
  "name_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del Archivo"])},
  "data_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Creación"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "gpt_module_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activación del Módulo ChatGpt"])},
  "help_desk_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deje sus datos y nos pondremos en contacto con usted lo antes posible"])},
  "name_and_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre y apellido *"])},
  "email_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico empresarial *"])},
  "request_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuéntenos sobre su solicitud *"])},
  "privacy_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consiento el tratamiento de mis datos y declaro haber leído la Política de privacidad."])},
  "anonymation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te informamos que al cambiar de estado anónimo a visible, los botones 'Sugerencia Chatgpt' se desactivarán para garantizar tu privacidad."])},
  "updateGraphApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar datos"])},
  "lastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último acceso"])},
  "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
  "performanceArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo de Performance"])},
  "performanceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de Performance"])},
  "skillsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de Competencias"])},
  "totalRegression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de regresiones"])},
  "totalPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de performance"])},
  "regressionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pantalla proporciona una visión detallada de los gráficos relacionados con correlaciones y regresiones entre variables.<br><br> Aquí encontrarás una serie de gráficos y herramientas dedicadas al análisis de las relaciones entre variables dentro del contexto empresarial. Las regresiones son una herramienta poderosa para entender cómo diferentes variables se influyen mutuamente, identificando la tendencia general y evaluando la fuerza y dirección de la correlación.<br><br> Gracias a esta función, podrás explorar las relaciones entre variables para entender mejor las tendencias de los datos.<br><br> Evalúa el impacto de las variables en el resultado deseado. Utiliza los resultados para tomar decisiones informadas y optimizar estrategias empresariales."])},
  "performanceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta visualización ofrece una panorámica detallada de los gráficos relacionados con el rendimiento empresarial.<br><br> Aquí encontrarás una serie de gráficos y herramientas dedicadas al análisis de las relaciones entre variables en el ámbito del rendimiento empresarial.<br><br>Estas herramientas son fundamentales para comprender cómo diferentes variables influyen en el rendimiento general, permitiéndote identificar las tendencias generales.<br><br>A través de esta función, puedes explorar el estado de las variables para obtener una mejor comprensión de las dinámicas de rendimiento y evaluar el impacto de cada variable en el logro de los resultados deseados.<br><br>Los resultados obtenidos pueden utilizarse para tomar decisiones informadas y optimizar las estrategias empresariales en términos de rendimiento."])},
  "potentialInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta representación proporciona una panorámica detallada de los gráficos relacionados con el potencial de rendimiento.<br><br>Aquí tendrás acceso a una serie de gráficos y herramientas dedicadas al análisis de las relaciones entre variables en el contexto del potencial en comparación con el rendimiento.<br><br>Estas herramientas son fundamentales para comprender cómo diferentes variables influyen en el potencial general de la empresa, lo que te permite identificar las tendencias generales.<br><br>A través de esta funcionalidad, podrás explorar el estado de las variables para obtener una comprensión más profunda de las dinámicas del potencial empresarial y evaluar el impacto de cada variable en el logro de los resultados deseados.<br><br>Los resultados obtenidos pueden utilizarse para tomar decisiones informadas y optimizar las estrategias empresariales para maximizar el potencial empresarial."])},
  "salaryReviewInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta visualización proporciona un resumen detallado de los gráficos relacionados con la revisión salarial.<br><br> Aquí tendrás acceso a una serie de gráficos y herramientas dedicadas al análisis de las relaciones entre variables que contribuyen a la revisión salarial.<br><br>Estas herramientas son fundamentales para comprender cómo diferentes variables influyen en la revisión salarial de la empresa, lo que te permite identificar tendencias generales.<br><br>A través de esta funcionalidad, puedes explorar el estado de las variables para obtener una comprensión más profunda de la dinámica de la revisión salarial y evaluar el impacto de cada variable en el logro de los resultados deseados.<br><br> Los resultados obtenidos pueden utilizarse para tomar decisiones informadas y optimizar las estrategias empresariales para maximizar la revisión salarial."])},
  "infoJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pantalla proporciona una vista detallada a través de gráficos sobre las calificaciones promedio de habilidades, divididas por Job Title.<br><br>Aquí encontrarás información clara y visual sobre el estado de las habilidades, lo que te permitirá comprender completamente las calificaciones asociadas con cada Job Title.<br><br>Gracias a esta función, tendrás acceso a una visión general completa y detallada de las calificaciones, simplificando el proceso de toma de decisiones y el monitoreo del capital humano."])}
}
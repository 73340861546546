<template>
  <footer class="container-fluid">
    <div class="row gx-0 d-flex py-2 align-items-center ">
      <div class="col ">
        <div class="social_box d-flex gap-4 ">
          <div class="social pointer">
            <a href="https://www.facebook.com/hrcoffeeinnovation/" target="blank"><font-awesome-icon icon="fa-brands fa-facebook-f" /></a>
          </div>
          <div class="social pointer">
            <a href="https://www.instagram.com/hrcoffeeinnovation/" target="blank">
              <font-awesome-icon icon="fa-brands fa-instagram" />
            </a>
            
          </div>
          <div class="social pointer">
            <a href="https://it.linkedin.com/company/hrcoffee" target="blank">
              <font-awesome-icon icon="fa-brands fa-linkedin-in" />
            </a>
            
          </div>
        
        </div>
      
      </div>

      <div class="col d-flex justify-content-end align-items-center">

        <router-link class="router_link " :to="'/Privacy-by-design'">
          <small>
            Privacy by design
          </small>
        </router-link>
        <div class="poweredByHrcoffe">
          <a href="https://www.hrcoffee.it/" target="_blank" rel="noopener noreferrer"><small> &copy; Powered By HRCOFFEE </small></a>
      
        </div>
    
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'BaseFooter',
}
</script>

<style lang="scss" scoped>

footer{
  background-color: $hrcoffe-darkskyblue
  // color:white;

 

}
a{
  text-decoration: none;
  color: white;
}
.logo{
  width: 150px;
}
.router_link{
  color: rgb(255, 255, 255);
  padding: 0px 15px;
  border-right: 1px solid white;
  margin-right: 15px;
}
.poweredByHrcoffe{
  color: rgb(107, 107, 107);
}
.social_box{
  font-size: 15px;
}


</style>
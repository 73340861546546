import { createApp } from 'vue'
import App from './App.vue'
import * as d3 from "d3";
import store from './store' // importa il tuo Vuex store
import router from './router'
import '@/assets/js/axios.js'
// imposta jquery globalmente dopo averlo installato 
// import $ from 'jquery'
// window.jQuery = $
import './../src/assets/css/style.css'
import './../src/assets/scss/shadow.scss'
import './../node_modules/flag-icon-css/css/flag-icons.min.css'
// import "/node_modules/flag-icons/css/flag-icons.min.css";
// import 'flag-icon-css/css/flag-icon.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// CARICO IL FILE JS DEI FontAwesomeIcon 
import './assets/fontawesome/fontawesome.js'
import FlagIcon from 'vue-flag-icon'
import i18n from './i18n'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import VueLazyload from 'vue-lazyload';
// importing AOS css style globally
import 'aos/dist/aos.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// eslint-disable-next-line no-unused-vars
import * as data from '@/assets/js/config.js'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const app = createApp(App);


// libreria d3
app.config.globalProperties.$d3 = d3;  

app
.use(i18n)
.use(store)
.use(router)
.use(FlagIcon)
.use(VueSweetalert2)
// .use(d3)
.use(VueLazyload, {
    lazyComponent: true
  })
.component('font-awesome-icon', FontAwesomeIcon).mount('#app')
